import styled from 'styled-components';

interface ContainerProps {
  collapsed: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.collapsed ? 'center' : 'space-between')};
  padding: 24px 12px 24px 16px;
  height: 72px;
  border-top: 1px solid #464c71;
  cursor: pointer;
  color: #b5b9d3;
  white-space: nowrap;
`;
