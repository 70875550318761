import * as Yup from 'yup';
import { emailMaskRegex } from '../../../services/utilities';

export interface ProfileInitialValues {
  name: string;
  email: string;
}

export const validationSchema: Yup.ObjectSchemaDefinition<ProfileInitialValues> = {
  name: Yup.string()
    .max(100, 'max length 100 symbols')
    .required('Required'),
  email: Yup.string()
    .matches(emailMaskRegex, 'Email is not valid')
    .required('Required'),
};
