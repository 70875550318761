import { LayoutMode, VIDEO_PADDING } from '../common/constants/constants';
import { HelpersStrings } from '../common/localization/en';
import qs from 'qs';
import { domainZones } from './domainZones';

interface Location {
  search: string;
}

export const downloadMedia = (path: string) => {
  const a = document.createElement('a');
  const [first, second] = path.split('/upload/');
  a.setAttribute('href', `${first}/upload/fl_attachment/${second}`);
  a.setAttribute('download', 'My story.mp4');
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const getSortFieldFromSortString = (sortString: string | undefined): string => {
  return sortString ? sortString.split(',')[0] : '';
};

export const getSortDirectionFromSortString = (sortString: string | undefined): string => {
  if (!sortString) return '';
  return sortString.split(',')[1] || '';
};

export const convertApiPageToFrontEndPage = (page: number) => {
  return page + 1;
};
export const convertFrontEndPageToApiPage = (page: number) => {
  return page - 1;
};

// https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export function formatBytes(a: number, b = 2) {
  if (0 === a) return '0 Bytes';
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));
  return (
    parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
    ' ' +
    ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
  );
}

export const getVideoImgUrlFromS3 = (urlS3: string | null, urlCDN: string) => {
  if (!urlS3) {
    return '';
  }

  const indexPathUrl =
    urlS3.indexOf('/video/') !== -1 ? urlS3.indexOf('/video/') : urlS3.indexOf('/stories/');
  const pathUrl = urlS3.substring(indexPathUrl);
  return urlCDN + pathUrl;
};

export const getStringValue = (val: string | number | null): string => {
  return val || typeof val === 'number' ? val.toString() : HelpersStrings.NotAvailable;
};

export const getLocaleNumberValue = (val: number): string => {
  return val || val === 0 ? val.toLocaleString('en') : HelpersStrings.NotAvailable;
};

// export const getVideoCropUrl = ({ height, width, x, y, id }: VideoCropParams)  => {
//   return `https://res.cloudinary.com/resistr/video/upload/c_crop,h_${height},w_${width},x_${x},y_${y}/v1/${id}.mp4`;
// }

export const getVideoInMp4 = (url: string) => {
  let l = url.split('.');
  l.pop();
  let b = l.join('.');
  return b + '.mp4';
};

type VideoCropParams = {
  height: number;
  width: number;
  x: number;
  y: number;
  id: string;
  subdomain: string;
};

export const getVideoCropUrl = ({ height, width, x, y, id, subdomain }: VideoCropParams) => {
  return `https://res.cloudinary.com/resistr/video/upload/c_crop,h_${height},w_${width},x_${x},y_${y}/v1/${subdomain}/${id}.mp4`;
};

export const getVideoPublicId = (url: string) => {
  const [, path] = url.split('s3.amazonaws.com/');
  const pathArr = path.split('.');
  pathArr.pop();
  return pathArr.join('.');
};

export const getImageFilterPublicId = (url: string) => {
  const [, urlString] = url.split('s3.amazonaws.com/');
  return urlString.replace(/\//g, ':').replace('.png', '');
};

export const getVideoOverlayLink = (videoUrl: string, imageUrl: string, subdomain: string) => {
  const videoPublicId = getVideoPublicId(videoUrl);
  const imagePublicId = getImageFilterPublicId(imageUrl);

  return `https://res.cloudinary.com/resistr/video/upload/c_fill,h_552,w_374/h_552,l_${subdomain}:${imagePublicId},w_374/${subdomain}/${videoPublicId}.mp4`;
};

export const getCloudinaryUrlForFilter = (url: string, subdomain: string) => {
  const [, publicId] = url.split('s3.amazonaws.com/');
  return `https://res.cloudinary.com/resistr/image/upload/${subdomain}/${publicId}`;
};

type Dimensions = { width: number; height: number };

export function getPaddedVideoUrl(url: string, dim: Dimensions) {
  const [first, second] = url.split('/upload/');
  return `${first}/upload/w_${dim.width},h_${dim.height +
    VIDEO_PADDING},c_pad,b_black,g_south/${second}`;
}

export function getPaddedUrlForEdge(url: string, dim: Dimensions, subdomain: string) {
  const [, second] = url.split('/videos/');
  return `https://res.cloudinary.com/resistr/video/upload/w_${dim.width},h_${dim.height +
    200},c_pad,b_black,g_south/v1/${subdomain}/videos/${second}`;
}

export async function getVideoDimensions(url: string): Promise<Dimensions> {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.onloadeddata = function(e) {
      // @ts-ignore
      const vid: HTMLVideoElement = e.target;
      resolve({
        width: vid.videoWidth,
        height: vid.videoHeight,
      });
    };
    video.src = url;
  });
}

export const getVideoFromS3 = (uri: string) => {
  return uri.replace('s3://', 'https://s3.amazonaws.com/');
};

export const getQueryParam = (location: Location, paramName: string): string => {
  const params = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as qs.ParsedQs;
  return params[paramName] as string;
};

export const emailMaskRegex = new RegExp(
  `^[a-z0-9!#$%&'*+/=?^_\`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_\`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.(${domainZones})$`,
);

export const getLayoutFormat = (screenWidth: number): LayoutMode => {
  if (screenWidth > 1024) return LayoutMode.Desktop;
  if (screenWidth <= 1024 && screenWidth >= 768) return LayoutMode.Tablet;
  if (screenWidth < 768) return LayoutMode.Mobile;
  return LayoutMode.Desktop;
};
export const getCloudinaryUrl = (url: string) => {
  // https://res.cloudinary.com/resistr/video/upload/sv-dev-stories/stories/3cf29f4e-7a6d-4764-a09d-af850e148a8a.mp4
  // http://storage.googleapis.com/sv-dev-stories/stories/3cf29f4e-7a6d-4764-a09d-af850e148a8a.mp4
  let l = url.split('?')[0] || '';
  // const CDN = "https://storage.googleapis.com/";
  // const CLOUDINARY = "https://res.cloudinary.com/resistr/video/upload/";
  // console.log({ l });
  // const link = l.replace(CDN, CLOUDINARY);
  // const b = getVideoInMp4(link);
  // console.log(b);
  return l;
};

export function getItemsFromPageAndSize<T>(items: T[], page: number, size: number): T[] {
  return items.filter((item, index) => index >= page * size).filter((item, index) => index < size);
}
