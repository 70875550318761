import React from 'react';
import { LoginFormComponent } from './LoginForm';
import { ForgotPasswordRequestComponent } from './ForgotPasswordRequest/ForgotPasswordRequest';
import { ForgotPasswordSuccessComponent } from './ForgotPasswordSuccess/ForgotPasswordSuccess';
import { StyledLogo, LoginContainer, LogoWrapper } from './Login.style';
import Logo from '../../common/assets/loginlogo.png';
import { Grid } from '@material-ui/core';

export interface ILoginProps {
  setStep: Function;
}

export const Login: React.FC = () => {
  const [step, setStep] = React.useState<
    'login' | 'forgotPasswordRequest' | 'forgotPasswordSuccess'
  >('login');
  return (
    <>
      <LoginContainer>
        <Grid container direction="row" alignItems="flex-start" spacing={3}>
          <Grid item xs={8} md={4}>
            <LogoWrapper>
              <StyledLogo src={Logo} />
            </LogoWrapper>
          </Grid>
          <Grid item xs={12}>
            {step === 'login' && <LoginFormComponent setStep={setStep} />}
            {step === 'forgotPasswordRequest' && (
              <ForgotPasswordRequestComponent setStep={setStep} />
            )}
            {step === 'forgotPasswordSuccess' && (
              <ForgotPasswordSuccessComponent setStep={setStep} />
            )}
          </Grid>
        </Grid>
      </LoginContainer>
    </>
  );
};
