import styled from 'styled-components';

interface CommonTableContainerP {
  padding: string;
}

export const CommonTableContainer = styled.div<CommonTableContainerP>`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  min-height: 120px;
  box-sizing: border-box;
  padding: ${(props) => props.padding};
  margin-bottom: 24px;
  font-size: 14px;
  font-weight: normal;
  color: #464c71;

  table {
    width: 98%;
    margin: 0 10px;
    border: 0;
    border-collapse: separate;
    border-spacing: 0px 10px;

    td,
    th {
      border: none;
    }
  }
`;
export const StyledCategory = styled.span`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  align-items: center;
  letter-spacing: 0.15px;
  color: #2d5af0;
  margin-left: 20px;
`;

export const SmallImageContainer = styled.div`
  position: relative;
  width: 34px;
  height: 34px;
  border-radius: 6px;
  overflow: hidden;
  margin-right: 14px;
`;

export const TableHeaderStyled = styled.thead`
  font-size: 10px;
  font-weight: bold;
  color: var(--textGrey);

  th {
    white-space: nowrap;
  }
`;

interface TableRowProps {
  selected: boolean;
}
export const TableRowStyled = styled.tr<TableRowProps>`
  color: var(--tableTextColor);
  border-radius: 8px;
  height: 64px;
  box-shadow: 0 2px 12px -2px rgba(10, 25, 107, 0.08);

  td {
    vertical-align: middle;
    border-top: 3px solid transparent !important;
    border-bottom: 3px solid transparent !important;
    background-color: var(--white);
    cursor: pointer;
  }
`;

export const ThStyled = styled.th`
  font-size: 14px;
  font-weight: normal;
  padding: 0 12px 6px 0 !important;
`;

export const ThSortable = styled(ThStyled)`
  cursor: pointer;
`;

export const SortableIcon = styled.i`
  font-size: 10px;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
`;

export const ImagesText = styled.div`
  font-weight: normal;
  font-size: 14px;
`;

export const DefaultImage = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 6px;
  background-color: #22aae1;
  margin-right: 14px;
`;

export const PaginationContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 10px 12px;
`;
export const ActionBox = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 400px;
  margin: 0 auto;
  padding: 10px 0;
`;
