import React from 'react';
import KeyboardTabIcon from '@material-ui/icons/KeyboardTab';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../../../../store';
import { reset as resetHeroes } from '../../../../../store/slices/heroesSlice';
import { reset as resetVenue } from '../../../../../store/slices/venueSlice';
import { reset as resetUi } from '../../../../../store/slices/uiSlice';
import { reset as resetStoryVideos } from '../../../../../store/slices/storyVideosSlice';
import { reset as resetMe } from '../../../../../store/slices/meSlice';
import { reset as resetCampaignsStoryVideos } from '../../../../../store/slices/campaignsStoryVideosSlice';
import { reset as resetCampaigns } from '../../../../../store/slices/campaignsSlice';

import { UnprotectedRoutes } from '../../../../constants/routes';

import { httpClient } from '../../../../../services/httpClient/httpClient';

import { SidebarStrings } from '../../../../localization/en';

import { Container, Text } from './LogoutSection.style';

interface LogoutSectionProps {
  collapsed: boolean;
}

export const LogoutSection: React.FC<LogoutSectionProps> = (props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { collapsed } = props;

  const logOut = () => {
    httpClient.logout();
    dispatch(resetHeroes());
    dispatch(resetVenue());
    dispatch(resetUi());
    dispatch(resetStoryVideos());
    dispatch(resetMe());
    dispatch(resetCampaignsStoryVideos());
    dispatch(resetCampaigns());
    history.push(UnprotectedRoutes.Login);
  };

  return (
    <Container collapsed={collapsed} onClick={logOut}>
      <KeyboardTabIcon />
      {!collapsed && <Text>{SidebarStrings.Logout}</Text>}
    </Container>
  );
};
