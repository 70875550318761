import React from 'react';
import Skeleton from 'react-loading-skeleton';
import Button from '@material-ui/core/Button';

import defaultUserAvatar from './schoolLogoMock.png';

import { SettingsPageStrings } from '../../../common/localization/en';

import {
  Container,
  PictureWrapper,
  PicturedLabel,
  PicturedInput,
  UploadButton,
  Title,
} from './LogoSection.style';

interface LogoSectionProps {
  isLoading: boolean;
  pictureUrl: string | File;
  onChange: (pictureUrl: File) => void;
}

export const LogoSection: React.FC<LogoSectionProps> = (props) => {
  const { pictureUrl, onChange, isLoading } = props;

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    onChange(file);
  };

  const getPictureUrl = () => {
    if (typeof pictureUrl === 'object') {
      return URL.createObjectURL(pictureUrl);
    }

    return pictureUrl;
  };

  const getPictureBlock = () => {
    const userAvatar = pictureUrl ? getPictureUrl() : defaultUserAvatar;

    return <img src={userAvatar} alt="logo" />;
  };

  return (
    <Container>
      <Title>{SettingsPageStrings.LogoTitle}</Title>
      <PictureWrapper>
        {isLoading ? <Skeleton height={198} width={242} /> : getPictureBlock()}
      </PictureWrapper>
      <PicturedInput
        id="contained-button-file"
        type="file"
        accept="image/*"
        onChange={onChangeHandler}
      />
      <PicturedLabel htmlFor="contained-button-file">
        <UploadButton>
          <Button variant="contained" color="primary" component="span">
            {SettingsPageStrings.UpdateLogo}
          </Button>
        </UploadButton>
      </PicturedLabel>
    </Container>
  );
};
