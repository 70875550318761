import React, { useRef } from 'react';

import { VideoWrapperNew } from './TableVideo.style';

interface VideoProps {
  videoUrl: string;
}

export const Video: React.FC<VideoProps> = ({ videoUrl }) => {
  const refVideo = useRef<HTMLVideoElement>(null);

  return (
    <>
      <VideoWrapperNew>
        <video ref={refVideo} muted playsInline loop>
          <source src={videoUrl} type="video/mp4" />
        </video>
      </VideoWrapperNew>
    </>
  );
};
