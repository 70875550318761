import React, { useMemo } from 'react';

import TextField from '@material-ui/core/TextField';

interface TextFieldProps {
  label: string;
  name: string;
  value: string | number;
  errorText?: string;
  type?: string;
  onChange: (value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  disabled?: boolean;
}

export const TextFieldComponent: React.FC<TextFieldProps> = (props) => {
  const { label, name, value, errorText, type = 'text', onChange, disabled = false } = props;

  const labelValue = useMemo(() => {
    return errorText ? errorText : label;
  }, [errorText, label]);

  return (
    <TextField
      disabled={disabled}
      name={name}
      error={Boolean(errorText)}
      label={labelValue}
      value={value}
      type={type}
      variant="outlined"
      onChange={onChange}
    />
  );
};
