import styled from 'styled-components';

export const SearchBarContainer = styled.div`
  display: flex;
  margin-bottom: 32px;
  width: 100%;
`;

export const SearchIcon = styled.img`
  width: 18px;
  height: 18px;
  color: var(--grey300);
`;
