import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { SubMenu } from '../subMenu/SubMenu';

import { IMenuItem, checkIsActiveItem, checkIsActiveSubMenuItem } from '../Menu.helper';

import { Container, SVListItem, SVListItemIcon, SubMenuWrapper } from './MenuItemCollapsed.style';

interface MenuItemCollapsedProps {
  item: IMenuItem;
}

export const MenuItemCollapsed: React.FC<MenuItemCollapsedProps> = (props) => {
  const { item } = props;
  const { pathname } = useLocation();
  const history = useHistory();
  const IsSubMenu = item.isSubMenu;

  const [isSubMenuCollapsed, setIsSubMenuCollapsed] = useState<boolean>(false);
  const [iconItemColor, setIconItemColor] = useState<string>('var(--sidebarIconColor)');

  useEffect(() => {
    const isActiveItem =
      checkIsActiveItem(pathname, item.route) ||
      checkIsActiveSubMenuItem(pathname, item);

    if (isActiveItem) {
      setIconItemColor('var(--sidebarActiveIconColor)');
    } else {
      setIconItemColor('var(--sidebarIconColor)');
    }
  }, [item, pathname]);

  const onClickSVListItemHandler = () => {
    history.push(item.route);
  };

  const onHoverItemHandler = () => {
    if (IsSubMenu) {
      setIsSubMenuCollapsed(!isSubMenuCollapsed);
    }
  };

  return (
    <Container onMouseEnter={onHoverItemHandler} onMouseLeave={onHoverItemHandler}>
      <SVListItem button onClick={onClickSVListItemHandler}>
        <SVListItemIcon>
          {React.cloneElement(item.icon as React.ReactElement, { color: iconItemColor })}
        </SVListItemIcon>
      </SVListItem>
      {IsSubMenu && isSubMenuCollapsed && (
        <SubMenuWrapper>
          <SubMenu
            collapsed
            item={item}
            isSubMenuCollapsed={isSubMenuCollapsed}
            onClickCollapseManagerHandler={onClickSVListItemHandler}
          />
        </SubMenuWrapper>
      )}
    </Container>
  );
};
