import React, { memo, useEffect } from 'react';

import TransitionWrapper from '../TransitionWrapper/TransitionWrapper';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { fetchUserDetails } from '../../../store/slices/userDetailsSlice';
import { DetailsModalContent } from './DetailsModalContent/DetailsModalContent';

interface NewDetailsModalProps {
  storyId?: string;
  userId: string;
  isOpen: boolean;
  handleClose: () => void;
}
export const NewDetailsModal: React.FC<NewDetailsModalProps> = memo(
  ({ isOpen, handleClose, userId, storyId }) => {
    const dispatch = useAppDispatch();
    const [story] = useTypedSelector(state =>
      state.storyVideos.items.filter(item => item.id === storyId)
    );

    useEffect(() => {
      if (userId) {
        dispatch(fetchUserDetails(userId));
      }
    }, [userId, dispatch]);

    return (
      <TransitionWrapper show={isOpen}>
        <DetailsModalContent userId={userId} handleClose={handleClose} story={story} />
      </TransitionWrapper>
    );
  }
);
