import React from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import MobilePagination from '@material-ui/lab/Pagination';
import { PaginationContainer } from './CommonTable.style';
import {
  convertApiPageToFrontEndPage,
  convertFrontEndPageToApiPage,
} from '../../../services/utilities';
import { useWindowResize } from '../../../services/hooks/useWindowResize';
import { LayoutMode } from '../../constants/constants';

interface PaginatorProps {
  totalPages: number;
  totalItems: number;
  page: number;
  size: number;
  onChangeSize: (size: number) => void;
  onPageClick: (page: number) => void;
}

export const Pagination: React.FC<PaginatorProps> = ({
  page,
  size,
  onChangeSize,
  totalItems,
  onPageClick,
}) => {
  const device = useWindowResize();
  const handleChangePage = (event: React.ChangeEvent<unknown> | null, newPage: number) => {
    onPageClick(convertApiPageToFrontEndPage(newPage));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const rowsNumber = parseInt(event.target.value, 10);
    onChangeSize(rowsNumber);
  };

  const renderPagination = () => {
    switch (device) {
      case LayoutMode.Desktop: {
        return (
          <TablePagination
            component="div"
            count={totalItems}
            page={convertFrontEndPageToApiPage(page)}
            onChangePage={handleChangePage}
            rowsPerPage={size}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        );
      }
      case LayoutMode.Tablet: {
        return (
          <MobilePagination
            count={size}
            size="medium"
            page={convertFrontEndPageToApiPage(page)}
            onChange={handleChangePage}
          />
        );
      }
      case LayoutMode.Mobile: {
        return (
          <MobilePagination
            count={size}
            size="small"
            page={convertFrontEndPageToApiPage(page)}
            onChange={handleChangePage}
          />
        );
      }
      default: {
        return (
          <MobilePagination
            count={size}
            size="small"
            page={convertFrontEndPageToApiPage(page)}
            onChange={handleChangePage}
          />
        );
      }
    }
  };

  return <PaginationContainer>{renderPagination()}</PaginationContainer>;
};
