import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import ListItemText from '@material-ui/core/ListItemText';

import { ExpandLessIcon } from '../../../../../assets/newDesign/ExpandLessIcon';
import { ExpandMoreIcon } from '../../../../../assets/newDesign/ExpandMoreIcon';

import { SubMenu } from '../subMenu/SubMenu';

import { IMenuItem, checkIsActiveItem, checkIsActiveSubMenuItem } from '../Menu.helper';

import { SVBadge } from '../Menu.style';
import {
  Container,
  SVListItem,
  SVListItemIcon,
  SubMenuWrapper,
  SubMenuArrowsWrapper,
} from './MenuItem.style';
import { theme } from '../../../../../../theme';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  textActive: { color: theme.palette.secondary.main },
  text: { color: theme.palette.secondary.contrastText },
});

interface MenuItemProps {
  item: IMenuItem;
  itemClickHandler: () => void;
}

export const MenuItem: React.FC<MenuItemProps> = ({ item, itemClickHandler }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const history = useHistory();
  const IsSubMenu = item.isSubMenu;

  const [isActiveItem, setIsActiveItem] = useState<boolean>(false);
  const [isSubMenuCollapsed, setIsSubMenuCollapsed] = useState<boolean>(false);

  useEffect(() => {
    const isSubMenuOpened = checkIsActiveSubMenuItem(pathname, item);
    setIsActiveItem(checkIsActiveItem(pathname, item.route) || isSubMenuOpened);

    if (isSubMenuOpened) {
      setIsSubMenuCollapsed(true);
    } else {
      setIsSubMenuCollapsed(false);
    }
  }, [IsSubMenu, item, pathname]);

  const onClickArrowItemHandler = (event?: React.MouseEvent<HTMLDivElement>) => {
    if (event) {
      event.stopPropagation();
    }
    setIsSubMenuCollapsed(!isSubMenuCollapsed);
  };

  const onClickItemHandler = () => {
    history.push(item.route);
    itemClickHandler();
  };

  const getSubMenuArrows = () => {
    if (!IsSubMenu) {
      return null;
    }

    return (
      <SubMenuArrowsWrapper onClick={(event) => onClickArrowItemHandler(event)}>
        {isSubMenuCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      </SubMenuArrowsWrapper>
    );
  };

  const BadgeBlock = item.notification ? <SVBadge badgeContent={item.notification} /> : null;

  return (
    <Container>
      {!isSubMenuCollapsed && (
        <SVListItem button onClick={onClickItemHandler}>
          <SVListItemIcon>
            {React.cloneElement(item.icon as React.ReactElement, {
              color: isActiveItem
                ? theme.palette.secondary.main
                : theme.palette.secondary.contrastText,
            })}
          </SVListItemIcon>
          <ListItemText
            className={isActiveItem ? classes.textActive : classes.text}
            primary={item.text}
          />
          {BadgeBlock}
          {getSubMenuArrows()}
        </SVListItem>
      )}
      {IsSubMenu && isSubMenuCollapsed && (
        <SubMenuWrapper>
          <SubMenu
            item={item}
            isSubMenuCollapsed={isSubMenuCollapsed}
            onClickCollapseManagerHandler={onClickArrowItemHandler}
          />
        </SubMenuWrapper>
      )}
    </Container>
  );
};
