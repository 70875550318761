import styled from 'styled-components';

interface ContainerProps {
  collapsed: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  padding-left: 16px;
  height: 80px;
  background-color: var(--sidebarActiveBgr);
  ${(props) =>
    props.collapsed ? 'justify-content: center; padding-left: 0;' : 'justify-content: flex-start'};
  transition: 0.2s ease-in;

  div {
    ${(props) => props.collapsed && 'margin-right: 0'}
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 155px;
  font-size: 14px;
`;
export const UserName = styled.div`
  font-weight: bold;
  color: var(--white);
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const UserEmail = styled.div`
  color: #cfd2e4;
  text-overflow: ellipsis;
  overflow: hidden;
`;
