import styled from 'styled-components';
import { Badge, List } from '@material-ui/core';

export const Container = styled.div``;

export const SVList = styled(List)`
  margin-top: 30px;
  width: 100%;
`;

export const SVBadge = styled(Badge)`
  margin-right: 23px;

  .MuiBadge-badge {
    background-color: #f59f1e;
  }
`;
