import React from 'react';
import {
  CommonTableContainer,
  SortableIcon,
  TableHeaderStyled,
  TableRowStyled,
  ThSortable,
  SpinnerContainer,
  ThStyled,
} from './CommonTable.style';
import { Table } from 'react-bootstrap';
import { TableContent, TableRow } from '../../commonTypes';
import { Pagination } from './Pagination';
import { Spinner } from '../../assets/Spinner';
import { OrderDirection, defaultPagination } from '../../constants/constants';
import { TableStrings } from '../../localization/en';
import {
  getSortDirectionFromSortString,
  getSortFieldFromSortString,
} from '../../../services/utilities';

interface CommonTableProps {
  content: TableContent<TableRow>;
  isLoading?: boolean;
  noContent?: boolean;
  selectedRowId?: string;
  tablePadding?: string;
  onSortHeaderClick?: (name: string) => void;
  page: number;
  totalItems: number;
  totalPages: number;
  size: number;
  sort?: string[];
  goToPage?: (page: number) => void;
  onSizeChange: (size: number) => void;
  onClickRow?: (id: string) => void;
}

/*
 *  Table accepts TableContent but constraint to the schema, that we pass in parent component.
 *  So, the data, that you pass as content should look like this:
 *
 *  const myContent: TableContent<IMyContent> = { rows: [{...}], ... }
 * */
export const CommonTable: React.FC<CommonTableProps> = ({
  selectedRowId,
  content,
  tablePadding = '0 var(--sectionPadding)',
  onSortHeaderClick,
  page,
  totalItems,
  totalPages,
  size,
  sort,
  isLoading = false,
  noContent = false,
  goToPage = () => {},
  onSizeChange,
  onClickRow = () => {},
}) => {
  const renderTableHeader = () => {
    const headerCells: JSX.Element[] = [];
    const tableHeader = content.header!;
    for (const columnName of Object.keys(tableHeader!)) {
      const cell = tableHeader[columnName];
      if (cell.sortable && onSortHeaderClick) {
        let sortIcon = '';
        if (getSortFieldFromSortString(sort![0]) === columnName) {
          if (getSortDirectionFromSortString(sort![0]) === OrderDirection.DESC) {
            sortIcon = 'fas fa-sort-down';
          }
          if (getSortDirectionFromSortString(sort![0]) === OrderDirection.ASC) {
            sortIcon = 'fas fa-sort-up';
          }
        } else {
          sortIcon = 'fas fa-sort';
        }
        headerCells.push(
          <ThSortable key={columnName} onClick={() => onSortHeaderClick(columnName)}>
            {cell.name} &nbsp;
            <SortableIcon className={sortIcon} />
          </ThSortable>,
        );
      } else {
        headerCells.push(<ThStyled key={columnName}>{cell.name}</ThStyled>);
      }
    }
    return (
      <TableHeaderStyled>
        <tr>{headerCells}</tr>
      </TableHeaderStyled>
    );
  };

  const onClickRowHandler = (id: string) => () => {
    onClickRow(id);
  };

  const renderTableRowCells = (row: TableRow) => {
    const cells: JSX.Element[] = [];
    for (const columnName of Object.keys(row)) {
      const cell = row[columnName];

      if (cell && columnName !== 'id') {
        cells.push(<td key={columnName}>{cell}</td>);
      }
    }
    return <>{cells}</>;
  };

  return (
    <CommonTableContainer padding={tablePadding}>
      {isLoading && (
        <SpinnerContainer>
          <Spinner color="var(--spinnerColor)" />
        </SpinnerContainer>
      )}
      {noContent && <SpinnerContainer>{TableStrings.NoContent}</SpinnerContainer>}
      {!noContent && !isLoading && (
        <Table responsive>
          {content.header && renderTableHeader()}

          <tbody>
            {content.rows &&
              content.rows.map((row: TableRow, index) => (
                <TableRowStyled
                  selected={!!selectedRowId && row.id === selectedRowId}
                  key={index}
                  onClick={onClickRowHandler(row.id as string)}
                >
                  {renderTableRowCells(row)}
                </TableRowStyled>
              ))}
          </tbody>
        </Table>
      )}
      {totalItems > defaultPagination.size && !isLoading && (
        <Pagination
          totalPages={totalPages}
          page={page}
          onPageClick={goToPage}
          size={size}
          totalItems={totalItems}
          onChangeSize={onSizeChange}
        />
      )}
    </CommonTableContainer>
  );
};
