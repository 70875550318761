import React from 'react';
import { useWindowResize } from '../../../services/hooks/useWindowResize';
import { LayoutMode } from '../../constants/constants';
import { TopBarDesktop } from './topBarDesktop/TopBarDesktop';
import { TopBarTablet } from './topBarTablet/TopBarTablet';
import { TopBarMobile } from './topBarMobile/TopBarMobile';

export const TopBar: React.FC = () => {
  const sidebarMode = useWindowResize();

  switch (sidebarMode) {
    case LayoutMode.Desktop: {
      return <TopBarDesktop />;
    }
    case LayoutMode.Tablet: {
      return <TopBarTablet />;
    }
    case LayoutMode.Mobile: {
      return <TopBarMobile />;
    }
    default: {
      return <TopBarDesktop />;
    }
  }
};
