import React from 'react';
import { Box, IconButton } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { DetailsSidebarStrings } from '../../../localization/en';
import { theme } from '../../../../theme';

interface ModalHeaderProps {
  onClose: () => void;
}
export const ModalHeader: React.FC<ModalHeaderProps> = ({ onClose }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      borderBottom={`1px solid ${theme.palette.divider}`}
      paddingX={4}
      paddingY="20px"
    >
      <Box mr={2}>
        <IconButton color="primary" onClick={onClose}>
          <ArrowForward />
        </IconButton>
      </Box>
      <Box fontSize="14" mr="auto" color={theme.palette.text.primary}>
        {DetailsSidebarStrings.HeroesDetailsTitle}
      </Box>
    </Box>
  );
};
