import React, { useEffect } from 'react';
import {
  convertApiPageToFrontEndPage,
  convertFrontEndPageToApiPage,
  getItemsFromPageAndSize,
} from '../../../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import {
  createContentDataTableContent,
  createMobileContentDataTableContentFirstSlide,
  createMobileContentDataTableContentSecondSlide,
} from '../../../../services/helpers/tableMappers';
import {
  getStoryVideos,
  goToSelectedPage,
  setVideosSorting,
  updateSize,
} from '../../../../store/slices/storyVideosSlice';
import { StoryApiModel } from '../../../../api/models/storyVideos';
import { CommonTable } from '../../../../common/components/table/CommonTable';
import { useHistory } from 'react-router';
import { useWindowResize } from '../../../../services/hooks/useWindowResize';
import { LayoutMode } from '../../../../common/constants/constants';
import Slider from 'react-slick';

export const ContentTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const {
    isLoading,
    error,
    items,
    totalPages,
    totalItems,
    page,
    size,
    sort,
    search = '',
  } = useTypedSelector((state) => state.storyVideos);
  const venueId = useTypedSelector((state) => state.me.venueId);
  const device = useWindowResize();
  useEffect(() => {
    dispatch(
      getStoryVideos({
        venueId,
        pageable: {
          page,
          size,
          sort,
        },
        search,
      }),
    );
    // dispatch(getHeroes({ search, venueId, pageable: { page, size, sort } }));
  }, [dispatch, page, size, sort, search, venueId]);
  let visibleItems = getItemsFromPageAndSize<StoryApiModel>(items, page, size);
  const contentDataTableContents = createContentDataTableContent({ items: visibleItems });
  const mobileContentDataTableSecondSlide = createMobileContentDataTableContentSecondSlide({
    items: visibleItems,
  });
  const mobileContentDataTableFirstSlide = createMobileContentDataTableContentFirstSlide({
    items: visibleItems,
  });
  const onGoToPage = (targetPage: number) => {
    dispatch(goToSelectedPage(convertFrontEndPageToApiPage(targetPage)));
  };

  const onSort = (name: string) => {
    dispatch(setVideosSorting(name));
  };

  const onClickRow = (id: string) => {
    history.push(`/content/${id}`);
  };

  const changeSize = (size: number) => {
    dispatch(updateSize(size));
  };
  console.log('page number', convertApiPageToFrontEndPage(page));
  if (device === LayoutMode.Mobile) {
    const sliderSettings = {
      dots: true,
      arrows: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 5000,
      pauseOnHover: true,
      pauseOnFocus: true,
      cssEase: 'linear',
    };

    return (
      <Slider {...sliderSettings}>
        <div key={1}>
          <CommonTable
            content={mobileContentDataTableFirstSlide}
            page={convertApiPageToFrontEndPage(page)}
            sort={sort}
            totalItems={totalItems}
            totalPages={totalPages}
            isLoading={isLoading}
            noContent={error}
            tablePadding="0"
            goToPage={onGoToPage}
            onSortHeaderClick={onSort}
            onClickRow={onClickRow}
            size={size}
            onSizeChange={changeSize}
          />
        </div>
        <div key={2}>
          <CommonTable
            content={mobileContentDataTableSecondSlide}
            page={convertApiPageToFrontEndPage(page)}
            sort={sort}
            totalItems={totalItems}
            totalPages={totalPages}
            isLoading={isLoading}
            noContent={error}
            tablePadding="0"
            goToPage={onGoToPage}
            onSortHeaderClick={onSort}
            onClickRow={onClickRow}
            size={size}
            onSizeChange={changeSize}
          />
        </div>
      </Slider>
    );
  }

  return (
    <>
      <CommonTable
        content={contentDataTableContents}
        page={convertApiPageToFrontEndPage(page)}
        sort={sort}
        totalItems={totalItems}
        totalPages={totalPages}
        isLoading={isLoading}
        noContent={error}
        tablePadding="0"
        goToPage={onGoToPage}
        onSortHeaderClick={onSort}
        onClickRow={onClickRow}
        size={size}
        onSizeChange={changeSize}
      />
    </>
  );
};
