import styled from 'styled-components';

interface ContainerProps {
  collapsed: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  padding-left: 16px;
  ${(props) =>
    props.collapsed ? 'justify-content: center; padding-left: 0;' : 'justify-content: flex-start'};
  height: 104px;
  transition: 0.2s ease-in;

  div {
    ${(props) => props.collapsed && 'margin-right: 0'}
  }
`;

export const VenueName = styled.div`
  color: var(--white);
  overflow-wrap: break-word;
`;
