import { SidebarStrings } from '../localization/en';

export enum WebsiteRoutes {
  Content = '/content',
  Heroes = '/heroes',
  Settings = '/settings',
}

interface WebsiteRoutesName {
  route: WebsiteRoutes;
  name: SidebarStrings;
}
export const websiteRouteNameConnections: WebsiteRoutesName[] = [
  {
    route: WebsiteRoutes.Content,
    name: SidebarStrings.Content,
  },
  {
    route: WebsiteRoutes.Heroes,
    name: SidebarStrings.Heroes,
  },
  {
    route: WebsiteRoutes.Settings,
    name: SidebarStrings.Settings,
  },
];

export enum UnprotectedRoutes {
  Login = '/login',
}

export const getRouteById = (route: string, id: string): string => {
  return route.replace(':id?', id);
};
