import React from 'react';
import Grid from '@material-ui/core/Grid';
import { VideoCard } from './VideoCard';
import { makeStyles } from '@material-ui/core/styles';
import { StoryApiModel } from '../../../../api/models/storyVideos';
import { getLocalDateString } from '../../../../services/helpers/tableMappers';

const useStyles = makeStyles({
  root: {
    height: '100%',
  },
});

interface VideosSectionProps {
  items: StoryApiModel[];
}

export const VideosSection: React.FC<VideosSectionProps> = ({ items }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.root} container spacing={2}>
      {items.map((item) => (
        <Grid item key={item.id} xs={12} sm={6}>
          <VideoCard url={item.url} createdAt={getLocalDateString(item.createdAt)} />
        </Grid>
      ))}
    </Grid>
  );
};
