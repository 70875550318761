import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { IHttpClient, IHttpClientRequestParameters } from './IhttpClient';
import { HttpStatusCode } from '../../api/HttpStatusCodes';
import { ErrorMessages } from '../../api/constants';
import { AuthEndpoints } from '../../api/endpoints';
import { UploadMediaFileRequest, UploadMediaFileResponse } from '../../api/models/common';
import { MeApiModel, LoginRequestBody } from '../../api/models/auth';

axios.defaults.baseURL = process.env.REACT_APP_NEW_API_URL;

class HttpClient implements IHttpClient {
  login(parameters: IHttpClientRequestParameters<LoginRequestBody>): Promise<MeApiModel> {
    return new Promise<MeApiModel>((resolve, reject) => {
      const { url, payload } = parameters;
      axios
        .post(url, payload)
        .then((response: AxiosResponse<MeApiModel>) => {
          localStorage.setItem('authResponse', JSON.stringify(response.data));
          localStorage.setItem('authToken', response.data.accessToken);
          localStorage.setItem('refreshToken', response.headers['x-refresh-token']);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  logout() {
    localStorage.removeItem('authToken');
    localStorage.removeItem('refreshToken');
  }

  get<T, U>(parameters: IHttpClientRequestParameters<T>): Promise<U> {
    return new Promise<U>((resolve, reject) => {
      const { url, requiresToken, params } = parameters;

      const options: AxiosRequestConfig = {
        headers: {},
      };

      if (requiresToken) {
        options.headers['X-AUTH-TOKEN'] = this.getAccessToken();
      }

      if (params) {
        options.params = params;
      }

      axios
        .get(url, options)
        .then((response: AxiosResponse<U>) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (
            error?.response?.status === HttpStatusCode.Unauthorized &&
            error?.response?.data?.error === ErrorMessages.ExpiredJwtException
          ) {
            return this.handleExpiredJwt()
              .then(() =>
                this.get<T, U>(parameters).then((response) => {
                  resolve(response);
                }),
              )
              .catch(() => reject(error));
          }
          reject(error);
        });
    });
  }

  post<T, U>(parameters: IHttpClientRequestParameters<T>): Promise<U> {
    return new Promise<U>((resolve, reject) => {
      const { url, payload, requiresToken } = parameters;

      const options: AxiosRequestConfig = {
        headers: {},
      };

      if (requiresToken) {
        options.headers['X-AUTH-TOKEN'] = this.getAccessToken();
      }

      axios
        .post(url, payload, options)
        .then((response: AxiosResponse<U>) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (
            error.status === HttpStatusCode.Unauthorized &&
            error.error === ErrorMessages.ExpiredJwtException
          ) {
            return this.handleExpiredJwt()
              .then(() => this.post<T, U>(parameters))
              .then((response) => {
                resolve(response);
              })
              .catch(() => reject(error));
          }
          reject(error);
        });
    });
  }

  patch<T, U>(parameters: IHttpClientRequestParameters<T>): Promise<U> {
    return new Promise<U>((resolve, reject) => {
      const { url, payload, requiresToken } = parameters;

      const options: AxiosRequestConfig = {
        headers: {},
      };

      if (requiresToken) {
        options.headers['X-AUTH-TOKEN'] = this.getAccessToken();
      }

      axios
        .patch(url, payload, options)
        .then((response: AxiosResponse<U>) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (
            error.status === HttpStatusCode.Unauthorized &&
            error.error === ErrorMessages.ExpiredJwtException
          ) {
            return this.handleExpiredJwt()
              .then(() => this.patch<T, U>(parameters))
              .then((response) => {
                resolve(response);
              })
              .catch(() => reject(error));
          }
          reject(error);
        });
    });
  }

  put<T, U>(parameters: IHttpClientRequestParameters<T>): Promise<U> {
    return new Promise<U>((resolve, reject) => {
      const { url, payload, requiresToken } = parameters;

      const options: AxiosRequestConfig = {
        headers: {},
      };

      if (requiresToken) {
        options.headers['X-AUTH-TOKEN'] = this.getAccessToken();
      }

      axios
        .put(url, payload, options)
        .then((response: AxiosResponse<U>) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (
            error.status === HttpStatusCode.Unauthorized &&
            error.error === ErrorMessages.ExpiredJwtException
          ) {
            return this.handleExpiredJwt()
              .then(() => this.put<T, U>(parameters))
              .then((response) => {
                resolve(response);
              })
              .catch(() => reject(error));
          }
          reject(error);
        });
    });
  }

  delete<T, U>(parameters: IHttpClientRequestParameters<T>): Promise<U> {
    return new Promise<U>((resolve, reject) => {
      const { url, requiresToken } = parameters;

      const options: AxiosRequestConfig = {
        headers: {},
      };

      if (requiresToken) {
        options.headers['X-AUTH-TOKEN'] = this.getAccessToken();
      }

      axios
        .delete(url, options)
        .then((response: AxiosResponse<U>) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (
            error.status === HttpStatusCode.Unauthorized &&
            error.error === ErrorMessages.ExpiredJwtException
          ) {
            return this.handleExpiredJwt()
              .then(() => this.delete<T, U>(parameters))
              .then((response) => {
                resolve(response);
              })
              .catch(() => reject(error));
          }
          reject(error);
        });
    });
  }

  uploadFile(
    parameters: IHttpClientRequestParameters<UploadMediaFileRequest>,
  ): Promise<UploadMediaFileResponse> {
    return new Promise<UploadMediaFileResponse>((resolve, reject) => {
      const { url, payload } = parameters;

      if (!payload) return reject();

      const options: AxiosRequestConfig = {
        headers: {},
      };

      options.headers['X-AUTH-TOKEN'] = this.getAccessToken();
      options.headers['Content-Type'] = 'multipart/form-data';

      const formData = new FormData();
      formData.append('mediaFile', payload.mediaFile.file);
      formData.append('prefix', payload.prefix);

      axios
        .post(url, formData, options)
        .then((response: AxiosResponse<UploadMediaFileResponse>) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (
            error.status === HttpStatusCode.Unauthorized &&
            error.error === ErrorMessages.ExpiredJwtException
          ) {
            return this.handleExpiredJwt()
              .then(() => this.uploadFile(parameters).then((response) => resolve(response)))
              .catch(() => reject(error));
          }
          reject(error);
        });
    });
  }

  handleExpiredJwt(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      const options: AxiosRequestConfig = {
        headers: {},
      };
      options.headers['x-refresh-token'] = this.getRefreshToken();
      return axios
        .get(AuthEndpoints.RefreshToken, options)
        .then((response: AxiosResponse) => {
          localStorage.setItem('authToken', response.headers['x-auth-token']);
          localStorage.setItem('refreshToken', response.headers['x-refresh-token']);
          resolve();
        })
        .catch(() => {
          this.logout();
          reject();
        });
    });
  }

  getAccessToken() {
    return `Bearer ${localStorage.getItem('authToken')}`;
  }

  getRefreshToken() {
    return `Bearer ${localStorage.getItem('refreshToken')}`;
  }

  isLoggedIn() {
    return !!localStorage.getItem('authToken');
  }
}

export const httpClient = new HttpClient();
