import styled from 'styled-components';

interface ContainerProps {
  collapsed: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  padding-left: 16px;
  height: 56px;
  ${(props) =>
    props.collapsed ? 'justify-content: center; padding-left: 0;' : 'justify-content: flex-start'};
  transition: 0.2s ease-in;
  cursor: pointer;

  svg {
    fill: var(--white);
  }
`;

export const Text = styled.div`
  margin-left: 16px;
  color: var(--white);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  white-space: nowrap;
`;
