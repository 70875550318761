import styled from 'styled-components';

export const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;

  button {
    text-transform: none;
  }
`;
