import React from 'react';
import {
  LoginContainer,
  LoginForm,
  LoginHeader,
  ForgotPasswordSubHeader,
  CardMediaStyled,
} from '../Login.style';
import { Grid, Button } from '@material-ui/core';
import { LoginPageStrings } from '../../../common/localization/en';
import { ILoginProps } from '../Login';
import backgroundUrl from '../../../common/assets/passwordSuccessBackground.png';

export const ForgotPasswordSuccessComponent: React.FC<ILoginProps> = ({ setStep }) => {
  return (
    <>
      <LoginContainer>
        <LoginForm>
          <Grid container spacing={3} direction="row" alignItems="center" justify="space-between">
            <Grid item xs={12} md={6}>
              <LoginHeader>{LoginPageStrings.ForgotPasswordSuccessTitle}</LoginHeader>

              <ForgotPasswordSubHeader>
                {LoginPageStrings.ForgotPasswordSuccessSubtitlePart1 +
                  LoginPageStrings.ForgotPasswordSuccessSubtitlePart2}
              </ForgotPasswordSubHeader>
            </Grid>

            <Grid item xs={12} md={5}>
              <CardMediaStyled src={backgroundUrl} />
            </Grid>

            <Grid item xs={12} md={3}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setStep('login')}
                fullWidth
              >
                Back To Login
              </Button>
            </Grid>
          </Grid>
        </LoginForm>
      </LoginContainer>
    </>
  );
};
