import { createMuiTheme } from '@material-ui/core/styles';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { Overrides } from '@material-ui/core/styles/overrides';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';

const palette: PaletteOptions = {
  primary: {
    main: '#2D5AF0',
    dark: '#163EC0',
    light: '#6A8DFF',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#FFB515',
    dark: '#E5A417',
    light: '#FFDF99',
    contrastText: '#8F94B6',
  },
  background: {
    default: '#FEFEFC',
    paper: '#FFFFFF',
  },
  success: {
    main: '#5DD6AA',
    dark: '#3B876B',
    light: '#7BC6AB',
    contrastText: '#FFFFFF',
  },
  info: {
    main: '#2196F3',
    dark: '#0B79D0',
    light: '#64B6F7',
    contrastText: '#FFFFFF',
  },
  warning: {
    main: '#FF8000',
    dark: '#C76300',
    light: '#FFA347',
    contrastText: '#FFFFFF',
  },
  error: {
    main: '#FF6340',
    dark: '#D93916',
    light: '#FF8C73',
    contrastText: '#FFFFFF',
  },
  common: {
    white: '#ffffff',
    black: '#000',
  },
  divider: '#EEEEF6',
  text: {
    primary: '#464c71',
  },
};

const breakpointValues = {
  xs: 0,
  sm: 600,
  md: 768,
  lg: 1280,
  xl: 1920,
};

const overrides: Overrides = {
  MuiPaper: {
    rounded: {
      borderRadius: '8px',
    },
  },
  MuiListItem: {
    gutters: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  MuiListItemIcon: {
    root: {
      minWidth: 24,
    },
  },
  MuiButton: {
    root: {
      borderRadius: 8,
      textTransform: 'none',
      fontSize: '15px',
      fontWeight: 600,
    },
  },
  MuiOutlinedInput: {
    root: {
      borderRadius: 12,
      border: '1px solid #EEEEF6',
    },
    notchedOutline: {
      border: '1px solid #cacae4',
    },
  },
  MuiTablePagination: {
    actions: {
      color: '#2D5AF0',
    },
  },
  MuiCardContent: {
    root: {
      padding: 'unset',
    },
  },
};

const typography: TypographyOptions = {
  /*body1: {
    color: palette.text?.primary,
  },*/
};

export const theme = createMuiTheme({
  palette,
  overrides,
  typography,
  breakpoints: { values: breakpointValues },
});
