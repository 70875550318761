import React from 'react';
import { ContentContainer } from './TableSection.style';
import { SearchBar } from '../../../common/components/searchBar/SearchBar';
import { HeroesTable } from './heroesTable/HeroesTable';
import { updateSearch } from '../../../store/slices/heroesSlice';

export const TableSection: React.FC = () => {
  return (
    <ContentContainer>
      <SearchBar entity="influencers" updateSearch={updateSearch} />
      <HeroesTable />
    </ContentContainer>
  );
};
