import styled from 'styled-components';
import { Badge, Grid } from '@material-ui/core';

export const StyledVideo = styled.video`
  max-height: 60vh;
  width: auto;
`;

export const StyledDate = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  align-items: center;
  letter-spacing: 0.15px;
  color: var(--grey);
`;
export const StyledCategory = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  align-items: center;
  letter-spacing: 0.15px;
  color: var(--lightBlue);
  margin-left: 20px;
`;
export const StyledSpanButton = styled.span`
  float: right;
  margin-right: 10px;
`;

export const StyledBadge = styled(Badge)`
  margin-left: 8px;
`;
export const StyledGridContainer = styled(Grid)`
  margin-left: 12px;
  margin-top: 12px;
`;
