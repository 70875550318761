import React from 'react';

interface SettingsIconProps {
  color?: string;
}

export const SettingsIcon: React.FC<SettingsIconProps> = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill={color}
        fillRule="evenodd"
        d="M18.418 12.4l-.722-.417.722-.38a2 2 0 00.734-2.728l-1-1.74a2.008 2.008 0 00-2.746-.735l-.72.416.02-.816a2 2 0 00-2-2h-2a2 2 0 00-2 2v.826l-.7-.427a2.005 2.005 0 00-2.74.735l-1 1.74A2 2 0 005 11.602l.708.409-.708.388a2 2 0 00-.734 2.728l1 1.74a2.005 2.005 0 002.74.735l.72-.416-.02.814a2 2 0 002 2h2a2 2 0 002-2v-.826l.7.427a2.007 2.007 0 002.74-.735l1-1.74a2 2 0 00-.728-2.726zm-1.726 1.314l.723.417-1 1.74-.72-.416a1.968 1.968 0 00-1.971 0l-.028.016a1.959 1.959 0 00-.988 1.7V18h-2v-.826a1.962 1.962 0 00-.987-1.7l-.03-.016a1.966 1.966 0 00-1.97 0l-.72.416-1-1.74.705-.413a1.987 1.987 0 000-3.442l-.71-.409 1-1.74.72.416a1.967 1.967 0 001.972 0l.028-.016a1.959 1.959 0 00.988-1.7V6h2v.826a1.962 1.962 0 00.988 1.7l.028.016a1.965 1.965 0 001.97 0l.72-.416 1 1.74-.722.417a1.961 1.961 0 00-.98 1.7v.024a1.958 1.958 0 00.984 1.707zM11.706 10a2 2 0 100 4 2 2 0 000-4z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};
