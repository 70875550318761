import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getApiUrlForId, UsersEndpoints } from '../../api/endpoints';
import { UserApiModel } from '../../api/models/users';
import { httpClient } from '../../services/httpClient/httpClient';
import { UserDetailsDataDTO } from '../storeModels';

const initialState: UserDetailsDataDTO = {
  isLoadingUserDetails: false,
  userDetails: null,
  isUserDetailsError: false,
};

export const fetchUserDetails = createAsyncThunk(
  'userDetails/fetchUserDetails',
  async (id: string, { rejectWithValue }) => {
    try {
      return await httpClient.get<null, UserApiModel>({
        url: getApiUrlForId(UsersEndpoints.GetUser, id),
        requiresToken: true,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchUserDetailsNoLoading = createAsyncThunk(
  'userDetails/fetchUserDetailsNoLoading',
  async (id: string) => {
    return await httpClient.get<null, UserApiModel>({
      url: getApiUrlForId(UsersEndpoints.GetUser, id),
      requiresToken: true,
    });
  }
);

export const UserDetailsSlice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {},
  extraReducers: (reducersBuilder) => {
    // fetch user details
    reducersBuilder.addCase(fetchUserDetails.rejected, (state) => {
      state.isUserDetailsError = true;
    });
    reducersBuilder.addCase(fetchUserDetails.pending, (state) => {
      state.isUserDetailsError = false;
      state.isLoadingUserDetails = true;
    });
    reducersBuilder.addCase(fetchUserDetails.fulfilled, (state, { payload }) => {
      state.isLoadingUserDetails = false;
      state.isUserDetailsError = false;
      state.userDetails = payload;
    });

    // update user info without loading
    reducersBuilder.addCase(fetchUserDetailsNoLoading.fulfilled, (state, { payload }) => {
      state.userDetails = payload;
    });
  },
});

export default UserDetailsSlice.reducer;
