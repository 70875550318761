import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import searchIcon from '../../assets/search.svg';
import { SearchBarContainer, SearchIcon } from './SearchBar.style';
import { useAppDispatch, useTypedSelector } from '../../../store';

interface SearchBarProps {
  entity: 'influencers' | 'storyVideos';
  updateSearch: Function;
}

export const SearchBar: React.FC<SearchBarProps> = ({ entity, updateSearch }) => {
  const dispatch = useAppDispatch();
  const { search } = useTypedSelector(state => state[entity]);

  const [searchValue, setSearchValue] = useState<string>(search || '');
  const changeSearchValue = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };
  const submitSearch = () => {
    dispatch(updateSearch(searchValue));
  };

  return (
    <SearchBarContainer>
      <TextField
        value={searchValue}
        onChange={e => changeSearchValue(e)}
        variant="outlined"
        label="Search"
        size="small"
        style={{ marginRight: '16px' }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon src={searchIcon} alt="" />
            </InputAdornment>
          ),
        }}
      />
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={submitSearch}
        disableElevation
      >
        Search
      </Button>
    </SearchBarContainer>
  );
};
