import React from 'react';
import { Box, IconButton } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { ContentModalHeaderStrings } from '../../../localization/en';

interface ContentModalHeaderProps {
  onClose: () => void;
}
export const ContentModalHeader: React.FC<ContentModalHeaderProps> = ({ onClose }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      borderBottom="1px solid #EEEEF6"
      borderColor="#EEEEF6"
      paddingX={4}
      paddingY="20px"
    >
      <Box mr={2}>
        <IconButton color="primary" onClick={onClose}>
          <ArrowForward />
        </IconButton>
      </Box>
      <Box fontSize="14" mr="auto">
        {ContentModalHeaderStrings.title}
      </Box>
    </Box>
  );
};
