import React, { useRef, useState } from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { makeStyles } from '@material-ui/core/styles';
import { SmallText } from './VideoCard.style';

const useStyles = makeStyles({
  root: {
    height: '306px',
    width: '203px',
  },
  actionArea: {
    position: 'relative',
  },
  video: {
    height: '258px',
  },
  contentCard: {
    padding: 'unset',
  },
  box: {
    display: 'flex',
    height: '45px',
    alignItems: 'center',
  },
  icon: {
    position: 'absolute',
    color: 'white',
    top: '113px',
    left: '85px',
    pointerEvents: 'none',
  },
});

interface VideoCardProps {
  url: string;
  createdAt: string;
}

export const VideoCard: React.FC<VideoCardProps> = ({ url, createdAt }) => {
  const classes = useStyles();
  const refVideo = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const playPauseSwitch = () => {
    if (!refVideo || !refVideo.current) return;
    const videoElement = refVideo.current;

    if (videoElement.paused) {
      videoElement.play().then(() => setIsPlaying(true));
    } else {
      videoElement.pause();
      setIsPlaying(false);
    }
  };

  return (
    <Card className={classes.root}>
      <CardActionArea className={classes.actionArea}>
        <CardMedia
          component="video"
          ref={refVideo}
          className={classes.video}
          src={url}
          onClick={() => playPauseSwitch()}
        />
        {!isPlaying && (
          <Box fontSize={32} className={classes.icon}>
            <PlayCircleOutlineIcon fontSize="inherit" color="inherit" />
          </Box>
        )}
      </CardActionArea>
      <CardContent className={classes.contentCard}>
        <Box className={classes.box} paddingX={2}>
          <CalendarTodayIcon height="12px" width="12px" />
          <Box mr={2} />
          <SmallText>Created</SmallText>
          <Box mr={2} />
          <SmallText>{createdAt}</SmallText>
        </Box>
      </CardContent>
    </Card>
  );
};
