import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TableDTO } from '../storeModels';
import { UserApiModel } from '../../api/models/users';
import { httpClient } from '../../services/httpClient/httpClient';
import { UsersEndpoints } from '../../api/endpoints';

import {
  getSortDirectionFromSortString,
  getSortFieldFromSortString,
} from '../../services/utilities';

import {
  GetTableDataRequest,
  GetTableDataResponse,
  GetTableDataWithSearchRequest,
} from '../../api/models/common';

import { tableLoadFulFilled, tableLoadPending, tableLoadRejected } from './utils';

import { defaultPagination, OrderDirection } from '../../common/constants/constants';
import { GetStoriesRequest } from '../../api/models/storyVideos';

const initialState: TableDTO<UserApiModel> = {
  error: false,
  isLoading: false,
  items: [],
  page: defaultPagination.page,
  size: defaultPagination.size,
  totalItems: defaultPagination.totalItems,
  totalPages: defaultPagination.totalPages,
  sort: defaultPagination.sortByLastCreated,
  lastUpdated: new Date().toISOString(),
};

export const getHeroes = createAsyncThunk(
  'heroes/getHeroes',
  async (options: GetTableDataWithSearchRequest, { rejectWithValue }) => {
    try {
      return await httpClient.get<GetStoriesRequest, GetTableDataResponse<UserApiModel>>({
        url: UsersEndpoints.GetUsersStats,
        requiresToken: true,
        params: {
          venueId: options.venueId,
          page: options.pageable.page?.toString(),
          size: options.pageable.size?.toString(),
          sort: options.pageable.sort?.toString(),
          search: options.search,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);
export const getUsers = createAsyncThunk(
  'heroes/getUsers',
  async (options: GetTableDataRequest, { rejectWithValue }) => {
    try {
      return await httpClient.get<GetTableDataRequest, GetTableDataResponse<UserApiModel>>({
        url: UsersEndpoints.GetUsers,
        requiresToken: true,
        params: options,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const heroesSlice = createSlice({
  name: 'heroes',
  initialState,
  reducers: {
    goToSelectedPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setUserDataSorting(state, action: PayloadAction<string>) {
      if (!state.sort) {
        state.sort = [];
      }
      const sortField = getSortFieldFromSortString(state.sort[0]);
      const sortDirection = getSortDirectionFromSortString(state.sort[0]);

      if (sortField && sortField === action.payload && sortDirection === OrderDirection.ASC) {
        state.sort[0] = `${action.payload},${OrderDirection.DESC}`;
      } else if (
        sortField &&
        sortField === action.payload &&
        sortDirection === OrderDirection.DESC
      ) {
        state.sort[0] = '';
      } else {
        state.sort[0] = `${action.payload},${OrderDirection.ASC}`;
      }
    },
    updateSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
      state.page = initialState.page;
    },
    updateSize(state, action: PayloadAction<number>) {
      state.size = action.payload;
      state.page = initialState.page;
    },
    updateTable(state) {
      state.lastUpdated = new Date().toISOString();
    },
    reset: () => initialState,
  },
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(getHeroes.rejected, (state) => {
      return tableLoadRejected(state);
    });
    reducersBuilder.addCase(getHeroes.pending, (state) => {
      return tableLoadPending(state);
    });
    reducersBuilder.addCase(getHeroes.fulfilled, (state, { payload }) => {
      return tableLoadFulFilled(state, payload);
    });
    reducersBuilder.addCase(getUsers.rejected, (state) => {
      return tableLoadRejected(state);
    });
    reducersBuilder.addCase(getUsers.pending, (state) => {
      return tableLoadPending(state);
    });
    reducersBuilder.addCase(getUsers.fulfilled, (state, { payload }) => {
      return tableLoadFulFilled(state, payload);
    });
  },
});

export const {
  goToSelectedPage,
  setUserDataSorting,
  updateTable,
  updateSearch,
  updateSize,
  reset,
} = heroesSlice.actions;
export default heroesSlice.reducer;
