import styled from 'styled-components';
import { TABLET_MAX_WIDTH } from '../constants/constants';

export const KindaBook = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 53px;
  border-radius: 14px 14px 0 0;
  width: 800px;
  height: 500px;
  background-color: var(--white);

  @media (max-width: ${TABLET_MAX_WIDTH}px) {
    width: 100%;
    height: fit-content;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 320px;
  min-height: 200px;
  box-sizing: border-box;
  padding: 0 14px;
  box-shadow: -8px -6px 13px -13px var(--pageHeaderBgr), 8px -6px 13px -13px var(--pageHeaderBgr);

  @media (max-width: ${TABLET_MAX_WIDTH}px) {
    :nth-child(2) {
      margin-top: 20px;
    }
  }
`;

export const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 48px;
`;

export const TableWrapper = styled.div`
  margin: 0 28px 10px;
  min-height: 650px;
  width: 918px;
  border-radius: var(--commonBorderRadius) var(--commonBorderRadius) 0 0;
  background-color: var(--white);
  box-shadow: 0px -18px 13px -2px var(--pageHeaderBgr);

  > div > div:first-child {
    padding-left: 25px;
  }

  @media (max-width: ${TABLET_MAX_WIDTH}px) {
    width: 100%;
  }
`;

export const Search = styled.i`
  color: var(--white);
  font-size: 12px;
`;

export const ModalFormWrapper = styled.div`
  form > div {
    margin-top: 30px;
  }
`;

export const PageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  padding: 80px 50px 30px;

  @media (max-width: ${TABLET_MAX_WIDTH}px) {
    padding: 30px 15px;
  }
`;

export const Title = styled.div`
  font-size: 12px;
  color: var(--titleGrey);
  text-transform: uppercase;
  font-weight: 600;
`;

export const Text = styled.div`
  font-size: 14px;
  color: var(--textGrey);
  font-weight: 500;
`;

export const PlayVideoImg = styled.img`
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
`;

export const CtaIcon = styled.i`
  display: inline-block;
  padding: 13px;
  margin-left: 24px;
  color: #222;
  font-size: 18px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  transition: all 0.2s ease-in-out;
  &:hover {
    color: #fff;
    background-color: #222;
  }
`;

interface LogoIconProps {
  imageUrl?: string;
}

export const LogoIcon = styled.div<LogoIconProps>`
  display: inline-block;
  min-height: 48px;
  min-width: 48px;
  border-radius: 14px;
  background-color: #d8d8d8;
  background-position: center center;
  background-size: contain;
  margin-right: 15px;
  ${(props) =>
    props.imageUrl &&
    `
      background-image: url(${props.imageUrl});
      
    `}
  background-repeat: no-repeat;
  background-size: contain;
`;

export const BodyContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  margin: 24px 24px 0 16px;
  padding: 32px 24px;
  box-sizing: border-box;
  border-radius: 16px;
`;
