export enum SidebarStrings {
  Overview = 'Overview',
  Dashboard = 'Dashboard',
  Heroes = 'Heroes',
  Content = 'Content',
  Settings = 'Settings',
  Filters = 'Filters',
  Codes = 'Codes',
  Logout = 'Log Out',
}

export enum LoginPageStrings {
  Title = 'Admin Panel',
  UserNameLabel = 'Your E-mail',
  Email = 'Email',
  PasswordLabel = 'Your Password',
  Password = 'Password',
  AuthError = 'Error logging in',
  EmailValidationError = 'Invalid Email',
  PasswordValidationError = 'Password should be longer then 5 symbols',
  InvalidUsername = 'Account not Found.',
  InvalidPassword = 'Incorrect password',
  Subtitle = 'Powered by SocialVenu',
  ForgotPasswordTitle = 'Forgot password ?',
  ForgotPasswordSubtitle = 'Enter your E-mail address below, and we will send you a link to reset your password.',
  ForgotPasswordSuccessTitle = 'Your Request has been successfully sent!',
  ForgotPasswordSuccessSubtitlePart1 = 'If we have a profile with the email address',
  ForgotPasswordSuccessSubtitlePart2 = 'then we have sent you an email with a link to reset your password',
}

export enum HeroesPageStrings {
  Heroes = 'Heroes',
  RegistrationDate = 'Join date',
  VideosCountDesktop = 'Number of videos',
  Score = 'Score(10)',
  Followers = 'Followers',
  Shares = 'Shares',
  Redemptions = 'Redemptions',
  Email = 'Email',
  PhoneNumber = 'Phone Number',
  CreatedAt = 'Created At',
  Friends = 'Friends',
  Code = 'Code',
}

export enum ContentPagesStrings {
  Search = 'Search',
  Videos = 'Videos',
  Name = 'Name',
  Categories = 'Categories',
  Actions = 'Actions',
  CreatedAt = 'Created At',
}

export enum TableStrings {
  NoContent = 'No Content',
}

export enum HelpersStrings {
  NotAvailable = 'N/A',
  Search = 'Search',
}

export enum VideosPageStrings {
  ModalEditVideoTitle = 'Edit video tile',
  SaveChanges = 'Save changes',
  DeleteLogo = 'Delete logo',
}

export enum SettingsPageStrings {
  UpdateLogo = 'Update Logo',
  FullName = 'Full Name',
  Email = 'E-mail',
  OldPassword = 'Old Password',
  NewPassword = 'New Password',
  ProfileSettings = 'Profile Settings',
  ResetPassword = 'Reset Password',
  ProfileSubmitButton = 'Update',
  PasswordSubmitButton = 'Update Password',
  LogoTitle = 'Upload logo for your admin page',
}

export enum ContentModalHeaderStrings {
  title = 'Video Details',
}

export enum DetailsSidebarStrings {
  HeroesDetailsTitle = 'User Additional Information',
  HeroName = 'Name',
  Email = 'E-mail',
  CreatedAt = 'Join Date',
  Instagram = 'Instagram',
}
