import React from 'react';
import { Grid, Box, CardMedia, Button } from '@material-ui/core';
import { UserApiModel } from '../../../../api/models/users';
import noAvatar from '../../../assets/no-avatar.jpg';
import { StoryApiModel } from '../../../../api/models/storyVideos';
import {
  StyledDate,
  StyledCategory,
  StyledSpanButton,
  StyledBadge,
  StyledGridContainer,
} from './ContentDetails.style';
import { downloadMedia } from '../../../../services/utilities';
import { getLocalDateString } from '../../../../services/helpers/tableMappers';
import { deleteStoryById } from '../../../../store/slices/storyVideosSlice';
import { useAppDispatch } from '../../../../store';
import { VideoCard } from '../VideosSection/VideoCard';
import { LayoutMode } from '../../../constants/constants';
import { useWindowResize } from '../../../../services/hooks/useWindowResize';

interface ModalInfoProps {
  userData: UserApiModel;
  story: StoryApiModel;
}

export const ContentModalInfo: React.FC<ModalInfoProps> = ({ userData, story }) => {
  const { photoUrl } = userData;
  const dispatch = useAppDispatch();
  const device = useWindowResize();
  const imageDimensionSize = device === LayoutMode.Desktop ? '100px' : '75px';
  const downloadFile = () => {
    if (story.url) {
      downloadMedia(story.url);
    }
  };
  const handleDelete = () => {
    dispatch(deleteStoryById({ id: story.id }));
  };
  return (
    <StyledGridContainer container direction="row" justify="center" alignItems="flex-start">
      <Grid item xs={6}>
        <VideoCard createdAt={getLocalDateString(story.createdAt, '/')} url={story.url} />
      </Grid>

      <Grid item xs={4} md={3}>
        <Box
          width={imageDimensionSize}
          height={imageDimensionSize}
          borderRadius="8px"
          overflow="hidden"
        >
          <CardMedia component="img" src={photoUrl || noAvatar} />
        </Box>

        <StyledDate>
          <StyledBadge color="primary" variant="dot" />
        </StyledDate>

        <StyledCategory>{story.categoryName}</StyledCategory>
      </Grid>
      <Grid item xs={2} md={2}>
        <a href={`/heroes/${userData.id}`}>{userData.name}</a>
      </Grid>
      <Grid item xs={12}>
        <StyledSpanButton>
          <Button variant="contained" color="primary" onClick={downloadFile}>
            Download
          </Button>
        </StyledSpanButton>
        <StyledSpanButton>
          <Button color="primary" onClick={handleDelete}>
            Delete
          </Button>
        </StyledSpanButton>
      </Grid>
    </StyledGridContainer>
  );
};
