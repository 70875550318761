import styled from 'styled-components';
import { Spinner } from '../../assets/Spinner';
import { CtaIcon } from '../../styles/commonStyles.style';
import { TABLET_MAX_WIDTH } from '../../constants/constants';

export const StyledSpinner = styled(Spinner)`
  width: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const VideoWrapper = styled.li`
  position: relative;
  display: flex;
  //margin-bottom: 45px;
  background-color: #222;
  border-radius: 14px;
  :focus {
    outline: none;
  }

  @media (max-width: ${TABLET_MAX_WIDTH}px) {
    height: 236px;
    width: 152px;
    margin-bottom: 25px;
  }
`;

export const VideoWrapperNew = styled(VideoWrapper)`
  height: 80px;
  width: 50px;
  margin-right: 20px;

  > video {
    border-radius: 14px;
  }
`;

export const DownloadIcon = styled(CtaIcon)`
  width: 46px;
  height: 46px;
  align-self: flex-end;
  margin-left: 10px;
`;

export const RatingText = styled.div`
  color: var(--brightGreen);
  font-size: 24px;
  font-weight: 500;
`;

export const InfoIconWrapper = styled.img`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 19px;
  height: 19px;
  cursor: pointer;
`;
