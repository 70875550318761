import React from 'react';

import { useAppDispatch, useTypedSelector } from '../../store';
import { patchVenue } from '../../store/slices/venueSlice';

import { Grid } from '@material-ui/core';

import { TopBar } from '../../common/components/topBar/TopBar';
import { LogoSection } from './LogoSection/LogoSection';
import { ProfileSection } from './ProfileSection/ProfileSection';
import { ResetPassword } from './ResetPassword/ResetPassword';

import { createUploadUrl, uploadLogoAsFile } from './Settings.helper';

import { BodyContentContainer } from '../../common/styles/commonStyles.style';
import { SettingsContainer } from './Settings.style';
import { getCloudinaryUrl } from '../../services/utilities';

export const Settings: React.FC = () => {
  const {
    venue: { logo, isLoading },
  } = useTypedSelector((state) => state.venue);
  const venueId = useTypedSelector((state) => state.me.venueId);
  const dispatch = useAppDispatch();
  const onChangeLogoHandler = (logoFile: File) => {
    createUploadUrl(venueId, logoFile.name.split('.').pop()).then((res) => {
      if (res) {
        let url = getCloudinaryUrl(res.url);
        uploadLogoAsFile({ options: res, data: logoFile });
        setTimeout(() => {
          dispatch(patchVenue({ id: venueId, update: { logo: url } }));
        }, 1000);
      }
    });
    // uploadFileHandler(logoFile).then((result) => {
    //   console.log('result', result);

    //   dispatch(patchVenue({ logo: result.url }));
    // });
  };

  return (
    <SettingsContainer>
      <TopBar />
      <BodyContentContainer>
        <Grid container spacing={2}>
          <Grid item lg={3} md={4} xs={12}>
            <LogoSection isLoading={isLoading} pictureUrl={logo} onChange={onChangeLogoHandler} />
          </Grid>
          <Grid item md={6} xs={12}>
            <ProfileSection />
            <ResetPassword />
          </Grid>
        </Grid>
      </BodyContentContainer>
    </SettingsContainer>
  );
};
