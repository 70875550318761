import { Draft } from 'immer';
import { TableDTO } from '../storeModels';
import { GetTableDataResponse } from '../../api/models/common';
import { getSortDirectionFromSortString } from '../../services/utilities';
import { OrderDirection } from '../../common/constants/constants';

export function tableLoadRejected<T>(state: TableDTO<T>): TableDTO<T> {
  state.isLoading = false;
  state.error = true;
  return state;
}

export function tableLoadPending<T>(state: TableDTO<T>): TableDTO<T> {
  state.isLoading = true;
  state.error = false;
  return state;
}

export function tableLoadFulFilled<T>(
  state: TableDTO<T>,
  payload: GetTableDataResponse<T>,
): TableDTO<T> {
  state.items = payload.items;
  state.isLoading = false;
  state.totalItems = payload.totalItems;
  state.totalPages = payload.totalPages;
  state.page = payload.page;
  return state;
}

export const getVideoThumbnail = (uri: string) =>
  uri.replace('s3://', 'https://s3.amazonaws.com/') + '_t.jpg';

export function setTableSorting<T>(state: Draft<TableDTO<T>>, payload: string): Draft<TableDTO<T>> {
  const sortDirection = getSortDirectionFromSortString(state.sort[0]);

  if (sortDirection === OrderDirection.ASC) {
    state.sort[0] = `${payload},${OrderDirection.DESC}`;
  } else {
    state.sort[0] = `${payload},${OrderDirection.ASC}`;
  }
  return state;
}

export function updateSliceLastUpdatedValue<T>(state: Draft<TableDTO<T>>): Draft<TableDTO<T>> {
  state.lastUpdated = new Date().toISOString();
  return state;
}
