import React from 'react';

import { SmallImageContainer } from './CommonTable.style';

interface ImageComponentProps {
  imageRef: string;
  alt?: string;
}

export const ImageComponent: React.FC<ImageComponentProps> = ({ imageRef, alt = '' }) => {
  return (
    <SmallImageContainer>
      <img alt={alt} src={imageRef} />
    </SmallImageContainer>
  );
};
