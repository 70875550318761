import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const ContentContainer = styled.div`
  padding-bottom: 60px;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: var(--pageBackgroundColor);
`;
