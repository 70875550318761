export const BASE_URL = process.env.REACT_APP_BASE_API_URL;
export const NEW_URL = process.env.REACT_APP_NEW_API_URL;
export const AuthEndpoints = {
  NewLogin: `/api/v1/auth/customer`,
  Login: `/api/customer/auth/login`,
  RefreshToken: `/api/customer/auth/refresh`,
  GetMe: `/api/v1/customers/:id`,
  PatchProfile: `/api/customer/auth/me`,
  PatchMe: `/api/v1/customers/:id`,
};

export const VenueEndpoints = {
  Venue: `/api/customer/venue`,
  GetVenueAttributes: `/api/customer/venue/attributes`,
  CreateOrUpdateVenueAttributes: `/api/customer/venue/attributes`,
  PatchVenue: `/api/v1/venues/:id`,
  GetVenueByIdOrSubdomain: `/api/v1/venues/:id`,
};

export const UsersEndpoints = {
  GetUsers: `/api/v1/users`,
  GetUser: `/api/v1/users/:id`,
  GetUsersStats: `/api/v1/users/stats`,
  GetUserRewards: `/api/customer/users/:id/rewards`,
  GetUserPerformanceRecords: `/api/customer/user-performance-records`,
  AddPointsManually: `/api/customer/user-performance-records`,
  GetUserRefereeRedeemed: `/api/customer/users/:id/referee-redeemed`,
  VerifyPerformanceRecords: `/api/customer/user-performance-records/:id/verify`,
  UnverifyPerformanceRecords: `/api/customer/user-performance-records/:id/unverify`,
  UpdateUserReward: `/api/customer/users/:id/rewards/:userRewardId/:action`,
};

export const MediaEndpoints = {
  UploadFile: `/api/customer/media/upload`,
  UploadFileFromUrl: `/api/customer/media/upload-from-url`,
  CreateUploadSignedUrl: `/api/v1/venues/:id/assets/create-upload-signed-url`,
};

export const CampaignsEndpoints = {
  GetCampaigns: `/api/customer/amplfi-campaigns`,
  GetCampaignById: `/api/customer/amplfi-campaigns/:id`,
  CreateCampaigns: `/api/customer/amplfi-campaigns`,
  PatchCampaigns: `/api/customer/amplfi-campaigns/:id`,
  DeleteCampaignById: `/api/customer/amplfi-campaigns/:id`,
  UpdateCampaignFilter: `/api/customer/amplfi-campaigns/:id/update-video-filter`,
};

export const StoryVideosEndpoints = {
  GetVideos: `/api/v1/stories`,
  GetVideoById: `/api/customer/amplfi-story-videos/:id`,
  GetStories: `/api/v1/stories`,
  GetStoryById: `/api/v1/stories/:id`,
  DeleteStoryById: `/api/v1/stories/:id`,
};

export const AnalyticsEndpoints = {
  GetUsersGeoJson: `/api/customer/analytics/users_geojson`,
  GetGeneralKPIs: `/api/customer/analytics/kpi`,
  GetKPI: `/api/customer/analytics/kpi/:id`,
};

export const FiltersEndpoints = {
  GetFilters: `/api/customer/stock-video-filters`,
  CreateFilter: `/api/customer/stock-video-filters`,
  GetFilterById: `/api/customer/stock-video-filters/:id`,
  DeleteFilterById: `/api/customer/stock-video-filters/:id`,
  PatchFilterById: `/api/customer/stock-video-filters/:id`,
};

export const getApiUrlForId = (apiUrl: string, id: string): string => {
  return apiUrl.replace(/:id/, id);
};
