import React, { memo } from 'react';
import { Box } from '@material-ui/core';

import { Spinner } from '../../../assets/Spinner';
import { ModalHeader } from './ModalHeader';
import { ModalInfo } from './ModalInfo';
import { useTypedSelector } from '../../../../store';
import { StoryApiModel } from '../../../../api/models/storyVideos';
import { ContentModalInfo } from './ContentModalInfo';
import { ContentModalHeader } from './ContentModalHeader';

interface DetailsModalContentProps {
  userId: string;
  story?: StoryApiModel;
  handleClose: () => void;
}
export const DetailsModalContent: React.FC<DetailsModalContentProps> = memo(
  ({ handleClose, story }) => {
    const { isLoadingUserDetails, userDetails } = useTypedSelector((state) => state.userDetails);

    if (isLoadingUserDetails) {
      return (
        <Box bgcolor="#fff" borderRadius="8px 0 0 8px" height="calc(100vh - 80px)">
          <Spinner color="var(--lightBlue)" />
        </Box>
      );
    }
    if (story) {
      return (
        <Box
          bgcolor="#fff"
          borderRadius="8px 0 0 8px"
          height="calc(100vh - 80px)"
          overflow="scroll"
        >
          <ContentModalHeader onClose={handleClose} />
          {userDetails && <ContentModalInfo story={story} userData={userDetails} />}
        </Box>
      );
    }
    if (window.location.pathname.includes('heroes')) {
      return (
        <Box
          bgcolor="#fff"
          borderRadius="8px 0 0 8px"
          height="calc(100vh - 80px)"
          overflow="scroll"
        >
          <ModalHeader onClose={handleClose} />
          {userDetails && <ModalInfo userData={userDetails} />}
        </Box>
      );
    }
    return <div></div>;
  },
);
