import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import me from './slices/meSlice';
import influencers from './slices/heroesSlice';
import campaigns from './slices/campaignsSlice';
import campaignsStoryVideos from './slices/campaignsStoryVideosSlice';
import storyVideos from './slices/storyVideosSlice';
import venue from './slices/venueSlice';
import ui from './slices/uiSlice';
import userDetails from './slices/userDetailsSlice';

const store = configureStore({
  reducer: {
    me,
    influencers,
    campaigns,
    campaignsStoryVideos,
    storyVideos,
    venue,
    ui,
    userDetails,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
