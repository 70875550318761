import React from 'react';

import { SideBar } from './sideBar/SideBar';

import { Container, ContentContainer } from './Layout.style';

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <Container>
      <SideBar />
      <ContentContainer className="content-wrapper">{children}</ContentContainer>
    </Container>
  );
};
