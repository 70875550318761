import React from 'react';

import { LogoIcon } from '../../../../styles/commonStyles.style';
import { Container, VenueName } from './LogoSection.style';

interface LogoSectionProps {
  logoUrl?: string;
  collapsed: boolean;
  title: string;
}

export const LogoSection: React.FC<LogoSectionProps> = ({ logoUrl, collapsed, title }) => {
  return (
    <Container collapsed={collapsed}>
      <LogoIcon imageUrl={logoUrl} />
      {!collapsed && <VenueName>{title}</VenueName>}
    </Container>
  );
};
