import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TableDTO } from '../storeModels';
import { httpClient } from '../../services/httpClient/httpClient';
import { CampaignsEndpoints } from '../../api/endpoints';
import { GetTableDataRequest, GetTableDataResponse } from '../../api/models/common';
import { CampaignsApiModel, GetCampaignsAPIModel } from '../../api/models/campaigns';
import { setTableSorting, tableLoadFulFilled, tableLoadPending, tableLoadRejected } from './utils';
import { defaultPagination } from '../../common/constants/constants';

const initialState: TableDTO<CampaignsApiModel> = {
  error: false,
  isLoading: false,
  items: [],
  page: defaultPagination.page,
  size: defaultPagination.size,
  totalItems: defaultPagination.totalItems,
  totalPages: defaultPagination.totalPages,
  sort: defaultPagination.sort,
  lastUpdated: new Date().toISOString(),
};

export const getCampaigns = createAsyncThunk(
  'campaigns/getCampaigns',
  async (options: GetTableDataRequest, { rejectWithValue }) => {
    try {
      return await httpClient.get<GetTableDataRequest, GetTableDataResponse<GetCampaignsAPIModel>>({
        url: CampaignsEndpoints.GetCampaigns,
        requiresToken: true,
        params: options,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    goToSelectedPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setCampaignsSorting(state, action: PayloadAction<string>) {
      return setTableSorting(state, action.payload);
    },
    updateTable(state) {
      state.lastUpdated = new Date().toISOString();
    },
    reset: (state) => initialState,
  },
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(getCampaigns.rejected, (state) => {
      return tableLoadRejected(state);
    });
    reducersBuilder.addCase(getCampaigns.pending, (state) => {
      return tableLoadPending(state);
    });
    reducersBuilder.addCase(getCampaigns.fulfilled, (state, { payload }) => {
      return tableLoadFulFilled(state, payload);
    });
  },
});

export const { goToSelectedPage, updateTable, setCampaignsSorting, reset } = campaignsSlice.actions;
export default campaignsSlice.reducer;
