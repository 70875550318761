import styled from 'styled-components';

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--black);
`;

export const LoginHeader = styled.div`
  display: flex-start;
  position: relative;
  width: 100%;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
  color: var(--lightBlue);
`;

export const LoginSubHeader = styled.div`
  display: flex-start;
  position: relative;
  width: 100%;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  color: var(--lightBlue);
`;
export const ForgotPasswordSubHeader = styled.div`
  display: flex-start;
  position: relative;
  width: 100%;
  justify-content: center;
  font-size: 14px;
  font-weight: normal;
  color: var(--thinBlue);
`;

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    width: 700px;
    height: 450px;
    flexwrap: 'wrap';
  }
  @media (max-width: 768px) and (orientation: portrait) {
    width: 272px;
    height: 550px;
  }
  @media (max-width: 768px) and (orientation: landscape) {
    width: 412px;
    height: 450px;
  }
  width: 272px;
  height: 412px;
  padding: 14px;
  border-radius: 14px;
  box-sizing: border-box;
  background-color: var(--white);
`;
export const CardMediaStyled = styled.img`
  height: 80%;
  width: 100%;
`;
export const StyledLogo = styled.img`
  max-width: 400px;
  margin-left: 50px;
`;
export const LogoWrapper = styled.div`
  @media (min-width: 768px) {
    left: 40px;
    top: 40px;
  }
  @media (max-width: 768px) and (orientation: portrait) {
    left: 20px;
    top: 20px;
  }
  @media (max-width: 768px) and (orientation: landscape) {
    left: 40px;
    top: 40px;
  }
  @media (max-height: 670px) and (orientation: portrait) {
    left: 0px;
    top: 20px;
  }
  position: absolute;
  max-width: 304px;
  max-height: 36.92px;
  display: flex;
  left: 40px;
  top: 40px;
`;
