import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AuthHandlerContext } from '../../services/context/authHandler.context';

import { UnprotectedRoutes } from '../constants/routes';
import { httpClient } from '../../services/httpClient/httpClient';
import { useAppDispatch, useTypedSelector } from '../../store';
import { getVenue, getAttributes } from '../../store/slices/venueSlice';
// import { getMe } from '../../store/slices/meSlice';

type Props = {
  children?: React.ReactNode;
};

export const AuthHandler: React.FC = (props: Props) => {
  const history = useHistory();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const dispatch = useAppDispatch();
  const { auth, venueId } = useTypedSelector((state) => state.me);
  useEffect(() => {
    dispatch(getVenue({ id: venueId }));
    dispatch(getAttributes());
    // dispatch(getMe({ id }));
  }, [dispatch, auth, venueId]);

  useEffect(() => {
    const userIsLoggedIn = httpClient.isLoggedIn();
    if (!userIsLoggedIn) {
      setIsLoggedIn(userIsLoggedIn);
      history.push(UnprotectedRoutes.Login);
    } else {
      setIsLoggedIn(userIsLoggedIn);
    }
  }, [history]);

  return (
    <AuthHandlerContext.Provider value={isLoggedIn}>{props.children}</AuthHandlerContext.Provider>
  );
};
