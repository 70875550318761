import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { TopBar } from '../../common/components/topBar/TopBar';
import { WebsiteRoutes } from '../../common/constants/routes';
import { NewDetailsModal } from '../../common/components/NewDetailsModal/NewDetailsModal';
import { ContentContainer } from './Content.style';
import { TableSection } from './tableSection/TableSection';
import { useTypedSelector } from '../../store';

export const Content: React.FC = () => {
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const [story] = useTypedSelector((state) =>
    state.storyVideos.items.filter((item) => item.id === params.id),
  );

  const close = () => {
    history.push(WebsiteRoutes.Content);
  };

  return (
    <ContentContainer>
      <TopBar />
      <TableSection />
      <NewDetailsModal
        userId={story ? story.userId : ''}
        isOpen={!!params.id}
        storyId={params.id}
        handleClose={close}
      />
    </ContentContainer>
  );
};
