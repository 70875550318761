import styled from 'styled-components';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  margin: 24px 24px 0 16px;
  padding: 32px 24px;
  box-sizing: border-box;
  border-radius: 16px;
`;
