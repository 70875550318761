import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastProvider } from 'react-toast-notifications';

import store from './store';
import { AuthHandler } from './common/components/AuthHandler';
import { UnprotectedRoutes, WebsiteRoutes } from './common/constants/routes';

import { Layout } from './common/components/layout/Layout';

import { Login } from './pages/login/Login';
import { Content } from './pages/content/Content';
import { Settings } from './pages/settings/Settings';
import { Heroes } from './pages/heroes/Heroes';

import { MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { theme } from './theme';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { RootContainer, AppContainer } from './App.style';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <StylesProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <ToastProvider autoDismiss autoDismissTimeout={3500}>
            <Router>
              <AuthHandler>
                <RootContainer>
                  <AppContainer>
                    <Switch>
                      <Route exact path={UnprotectedRoutes.Login}>
                        <Login />
                      </Route>
                      <Route>
                        <Layout>
                          <Switch>
                            <Route path={`${WebsiteRoutes.Heroes}/:id?`}>
                              <Heroes />
                            </Route>
                            <Route path={WebsiteRoutes.Content + '/:id?'}>
                              <Content />
                            </Route>
                            <Route exact path={WebsiteRoutes.Settings}>
                              <Settings />
                            </Route>
                            <Redirect to={WebsiteRoutes.Content} />
                          </Switch>
                        </Layout>
                      </Route>
                    </Switch>
                  </AppContainer>
                </RootContainer>
              </AuthHandler>
            </Router>
          </ToastProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </Provider>
  );
};

export default App;
