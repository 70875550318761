import { ChosenFile } from '../../common/components/dragAndDrop/DragAndDropState';

export interface GetTableDataRequest {
  venueId: string;
  userId?: string;
  categoryId?: string;
  pageable: Pageable;
  // campaignId?: string;
  // stockVideoCampaignId?: string;
}

export interface GetTableDataWithSearchRequest extends GetTableDataRequest {
  search?: string;
}

export interface GetTableDataResponse<T> {
  items: T[];
  totalPages: number;
  totalItems: number;
  page: number;
  size: number;
}

export interface Pageable {
  page?: number;
  size?: number;
  sort?: string[];
}

export enum MediaFilePrefix {
  Client = 'client',
  Sponsors = 'sponsors',
}

interface TargetFile {
  file: File;
}
export interface UploadMediaFileRequest {
  mediaFile: ChosenFile | TargetFile;
  prefix: MediaFilePrefix;
}

export interface UploadMediaFileResponse {
  url: string;
}
