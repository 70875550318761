import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { TopBar } from '../../common/components/topBar/TopBar';
import { WebsiteRoutes } from '../../common/constants/routes';
import { NewDetailsModal } from '../../common/components/NewDetailsModal/NewDetailsModal';
import { HeroesContainer } from './Heroes.style';
import { TableSection } from './tableSection/TableSection';

export const Heroes: React.FC = () => {
  const params = useParams<{ id: string }>();
  const history = useHistory();

  const close = () => {
    history.push(WebsiteRoutes.Heroes);
  };

  return (
    <HeroesContainer>
      <TopBar />
      <TableSection />
      <NewDetailsModal isOpen={!!params.id} userId={params.id} handleClose={close} />
    </HeroesContainer>
  );
};
