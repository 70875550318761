import styled from 'styled-components';
import { ListItemIcon, ListItem } from '@material-ui/core';

export const Container = styled.div``;

export const SubMenuWrapper = styled.div`
  margin: 0 8px;
`;

export const SubMenuArrowsWrapper = styled.div`
  width: 20px;
  text-align: center;
`;

export const SVListItem = styled(ListItem)`
  padding-right: 7px !important;
`;

export const SVListItemIcon = styled(ListItemIcon)`
  margin-left: 16px;
  margin-right: 15px;
`;
