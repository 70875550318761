import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useToasts } from 'react-toast-notifications';

import { useAppDispatch, useTypedSelector } from '../../../store';
import { resetPasswordMe } from '../../../store/slices/meSlice';

import { SettingsPageStrings } from '../../../common/localization/en';

import {
  ResetPasswordInitialValues,
  validationSchema,
  initialValues,
} from './ResetPassword.helper';

import { TextFieldComponent } from '../../../common/components/TextField/TextField';

import { Container, FormStyled, SubmitButton, Title } from './ResetPassword.style';

export const ResetPassword: React.FC = () => {
  const { addToast } = useToasts();
  const { isLoading, id } = useTypedSelector((state) => state.me);
  const dispatch = useAppDispatch();

  const onSubmitHandler = (values: ResetPasswordInitialValues) => {
    dispatch(
      resetPasswordMe({ currentPassword: values.oldPassword, newPassword: values.newPassword, id }),
    ).then(() => {
      addToast('Password is updated', {
        appearance: 'success',
        autoDismiss: true,
      });
    });
  };

  const formikProfile = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object().shape<ResetPasswordInitialValues>(validationSchema),
    onSubmit: (values) => {
      onSubmitHandler(values);
    },
  });
  const { handleSubmit, values, handleChange, errors } = formikProfile;

  return (
    <Container>
      <Title>{SettingsPageStrings.ResetPassword}</Title>
      <FormStyled onSubmit={handleSubmit}>
        <TextFieldComponent
          type="password"
          name="oldPassword"
          label={SettingsPageStrings.OldPassword}
          value={values.oldPassword}
          onChange={handleChange}
          errorText={errors.oldPassword}
        />
        <TextFieldComponent
          type="password"
          name="newPassword"
          label={SettingsPageStrings.NewPassword}
          value={values.newPassword}
          onChange={handleChange}
          errorText={errors.newPassword}
        />
        <SubmitButton disabled={isLoading} variant="contained" color="primary" type="submit">
          {SettingsPageStrings.PasswordSubmitButton}
        </SubmitButton>
      </FormStyled>
    </Container>
  );
};
