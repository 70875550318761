import React, { useState } from 'react';
import { useAppDispatch, useTypedSelector } from '../../../../store';

import { LogoSection } from './logoSection/LogoSection';
import { UserLogoSection } from './userLogoSection/UserLogoSection';
import { LogoutSection } from './logoutSection/LogoutSection';
import { CollapseSection } from './collapseSection/CollapseSection';
import { Menu } from './menu/Menu';
import Drawer from '@material-ui/core/Drawer';

import { Container, FlexWrapper } from './SideBar.style';

import { useWindowResize } from '../../../../services/hooks/useWindowResize';
import { LayoutMode } from '../../../constants/constants';
import { triggerSidebar } from '../../../../store/slices/uiSlice';
import defaultUserAvatar from './schoolLogoMock.png';
export const SideBar: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    venue: { logo, name: venueName },
  } = useTypedSelector((state) => state.venue);
  const { sidebarOpen } = useTypedSelector((state) => state.ui);
  const { name, email } = useTypedSelector((state) => state.me);

  const sidebarMode = useWindowResize();
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const onCollapseHandler = () => {
    setCollapsed(!collapsed);
  };

  const closeSideBar = () => {
    dispatch(triggerSidebar());
  };

  const renderDesktopSidebar = () => {
    return (
      <Container collapsed={collapsed}>
        <FlexWrapper>
          <LogoSection
            logoUrl={logo || defaultUserAvatar}
            collapsed={collapsed}
            title={venueName}
          />
          <Menu collapsed={collapsed} />
        </FlexWrapper>
        <UserLogoSection collapsed={collapsed} imageUrl="" firstName={name} email={email} />
        <LogoutSection collapsed={collapsed} />
        <CollapseSection collapsed={collapsed} onCollapse={onCollapseHandler} />
      </Container>
    );
  };

  const renderTabletSidebar = () => {
    return (
      <Drawer open={sidebarOpen} onClose={closeSideBar}>
        <LogoSection logoUrl={defaultUserAvatar} collapsed={collapsed} title={venueName} />
        <UserLogoSection collapsed={collapsed} imageUrl="" firstName={name} email={email} />
        <Menu collapsed={collapsed} itemClickHandler={closeSideBar} />
        <LogoutSection collapsed={collapsed} />
      </Drawer>
    );
  };

  const renderMobileSidebar = () => {
    return (
      <Drawer open={sidebarOpen} onClose={closeSideBar}>
        <LogoSection logoUrl={defaultUserAvatar} collapsed={collapsed} title={venueName} />
        <UserLogoSection collapsed={collapsed} imageUrl="" firstName={name} email={email} />
        <Menu collapsed={collapsed} itemClickHandler={closeSideBar} />
        <LogoutSection collapsed={collapsed} />
      </Drawer>
    );
  };

  if (!sidebarMode) return null;

  switch (sidebarMode) {
    case LayoutMode.Desktop: {
      return renderDesktopSidebar();
    }
    case LayoutMode.Tablet: {
      return renderTabletSidebar();
    }
    case LayoutMode.Mobile: {
      return renderMobileSidebar();
    }
    default: {
      return renderDesktopSidebar();
    }
  }
};
