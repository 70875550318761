import { UserApiModel } from '../../../../../api/models/users';
import { getStringValue } from '../../../../../services/utilities';
import { DetailsSidebarStrings } from '../../../../localization/en';
import { getLocalDateString } from '../../../../../services/helpers/tableMappers';

interface DisplayDetailsDataItem {
  value: string;
  text: string;
}

export interface DisplayDetailsData {
  name: DisplayDetailsDataItem;
  email: DisplayDetailsDataItem;
  createdAt: DisplayDetailsDataItem;
  instagram: DisplayDetailsDataItem;
}

export const createDisplayData = (userData: UserApiModel): DisplayDetailsData => {
  return {
    name: {
      value: getStringValue(userData.name),
      text: DetailsSidebarStrings.HeroName,
    },
    email: {
      value: getStringValue(userData.email),
      text: DetailsSidebarStrings.Email,
    },
    createdAt: {
      value: getLocalDateString(userData.createdAt),
      text: DetailsSidebarStrings.CreatedAt,
    },
    instagram: {
      value: getStringValue(userData.instagramHandle || ''),
      text: DetailsSidebarStrings.Instagram,
    },
  };
};
