import React from 'react';

import { Container, UserInfo, UserName } from './UserLogoSection.style';

interface UserLogoSectionProps {
  collapsed: boolean;
  imageUrl?: string;
  firstName: string;
  email: string;
}

export const UserLogoSection: React.FC<UserLogoSectionProps> = (props) => {
  const { firstName, collapsed } = props;

  if (collapsed) {
    return null;
  }

  return (
    <Container collapsed={collapsed}>
      <UserInfo>
        <UserName>{firstName}</UserName>
      </UserInfo>
    </Container>
  );
};
