import React from 'react';
import { TableContent, TableRow } from '../../common/commonTypes';
import {
  HelpersStrings,
  HeroesPageStrings,
  ContentPagesStrings,
} from '../../common/localization/en';
import { Badge } from '@material-ui/core';
import { getLocaleNumberValue, getStringValue, downloadMedia } from '../utilities';

import { UserApiModel } from '../../api/models/users';
import { StoryApiModel } from '../../api/models/storyVideos';
import { ImageComponent } from '../../common/components/table/TableImage';
import {
  ImagesText,
  DefaultImage,
  StyledCategory,
  ActionBox,
} from '../../common/components/table/CommonTable.style';
import { Video } from '../../common/components/tableVideo/TableVideo';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useAppDispatch } from '../../store';
import { deleteStoryById } from '../../store/slices/storyVideosSlice';

export const getLocalDateString = (val: string | null, separator?: string): string => {
  if (val) {
    const dateObject = new Date(val);
    return dateObject.toLocaleDateString('en-US').replace(/\//g, separator || '-');
  } else {
    return HelpersStrings.NotAvailable;
  }
};

interface MapperProps<T> {
  items: T[];
}

export interface HeroesDataContent extends TableRow {
  id: string;
  name: JSX.Element;
  createdAt: string;
  stories: string;
}

export interface ContentDataContent extends TableRow {
  id: string;
  video: JSX.Element;
  createdAt: string;
  name: string;
  category: JSX.Element;
  actions: JSX.Element;
}
export interface MobileContentDataFirstSlide extends TableRow {
  id: string;
  video: JSX.Element;
  createdAt: string;
  name: string;
}
export interface MobileContentDataSecondSlide extends TableRow {
  id: string;
  category: JSX.Element;
  actions: JSX.Element;
}
interface DeleteButtonProps {
  id: string;
}
const DeleteButton: React.FC<DeleteButtonProps> = ({ id }): JSX.Element => {
  const dispatch = useAppDispatch();
  const handleDeleteStory = (e: React.MouseEvent): void => {
    e.stopPropagation();
    dispatch(deleteStoryById({ id }));
  };
  return <DeleteIcon color="action" onClick={handleDeleteStory} />;
};

const downloadVideo = (e: React.MouseEvent, url: string) => {
  e.stopPropagation();
  downloadMedia(url);
};

export const createUserDataTableContent = (
  props: MapperProps<UserApiModel>,
): TableContent<HeroesDataContent> => {
  const { items } = props;
  const tableContent: TableContent<HeroesDataContent> = {
    header: {
      name: {
        name: HeroesPageStrings.Heroes,
        sortable: true,
      },
      createdAt: {
        name: HeroesPageStrings.RegistrationDate,
        sortable: true,
      },
      stories: {
        name: HeroesPageStrings.VideosCountDesktop,
        sortable: true,
      },
    },
    rows: [],
  };

  if (items.length === 0) {
    return tableContent;
  }

  tableContent.rows = items.map((item) => {
    return {
      id: item.id,
      name: (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {item.photoUrl ? (
            <ImageComponent imageRef={item.photoUrl} alt="Sponsor card" />
          ) : (
            <DefaultImage />
          )}
          <ImagesText>{getStringValue(item.name)}</ImagesText>
        </span>
      ),
      createdAt: getLocalDateString(item.createdAt),
      stories: getLocaleNumberValue(item.stories),
    };
  });
  return tableContent;
};

// interface VideosWIthUsers extends StoryApiModel {
//   user: UserApiModel;
// }

export const createContentDataTableContent = (
  props: MapperProps<StoryApiModel>,
): TableContent<ContentDataContent> => {
  const { items } = props;
  const tableContent: TableContent<ContentDataContent> = {
    header: {
      video: {
        name: ContentPagesStrings.Videos,
        sortable: false,
      },
      createdAt: {
        name: ContentPagesStrings.CreatedAt,
        sortable: true,
      },
      name: {
        name: ContentPagesStrings.Name,
        sortable: false,
      },
      category: {
        name: ContentPagesStrings.Categories,
        sortable: false,
      },
      actions: {
        name: ContentPagesStrings.Actions,
        sortable: false,
      },
    },
    rows: [],
  };

  if (items.length === 0) {
    return tableContent;
  }

  tableContent.rows = items.map((item) => {
    return {
      id: item.id,
      video: (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {item.url ? <Video videoUrl={item.url} /> : <DefaultImage />}
        </span>
      ),
      createdAt: getLocalDateString(item.createdAt),
      name: item.userName ? getStringValue(item.userName) : 'test name',
      category: (
        <span>
          <Badge color="primary" variant="dot" />
          <StyledCategory>{getStringValue(item.categoryName)}</StyledCategory>
        </span>
      ),
      actions: (
        <ActionBox>
          <span>
            <DeleteButton id={item.id} />
          </span>
          <span>
            <GetAppIcon color="action" onClick={(e) => downloadVideo(e, item.url)} />
          </span>
        </ActionBox>
      ),
    };
  });
  return tableContent;
};
export const createMobileContentDataTableContentFirstSlide = (
  props: MapperProps<StoryApiModel>,
): TableContent<MobileContentDataFirstSlide> => {
  const { items } = props;
  const tableContent: TableContent<MobileContentDataFirstSlide> = {
    header: {
      video: {
        name: ContentPagesStrings.Videos,
        sortable: false,
      },
      createdAt: {
        name: ContentPagesStrings.CreatedAt,
        sortable: true,
      },
      name: {
        name: ContentPagesStrings.Name,
        sortable: false,
      },
    },
    rows: [],
  };

  if (items.length === 0) {
    return tableContent;
  }

  tableContent.rows = items.map((item) => {
    return {
      id: item.id,
      video: (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {item.url ? <Video videoUrl={item.url} /> : <DefaultImage />}
        </span>
      ),
      createdAt: getLocalDateString(item.createdAt),
      name: item.userName ? getStringValue(item.userName) : 'test name',
    };
  });
  return tableContent;
};

export const createMobileContentDataTableContentSecondSlide = (
  props: MapperProps<StoryApiModel>,
): TableContent<MobileContentDataSecondSlide> => {
  const { items } = props;
  const tableContent: TableContent<MobileContentDataSecondSlide> = {
    header: {
      category: {
        name: ContentPagesStrings.Categories,
        sortable: false,
      },
      actions: {
        name: ContentPagesStrings.Actions,
        sortable: false,
      },
    },
    rows: [],
  };

  if (items.length === 0) {
    return tableContent;
  }

  tableContent.rows = items.map((item) => {
    return {
      id: item.id,
      category: (
        <span>
          <Badge color="primary" variant="dot" />
          <StyledCategory>{getStringValue(item.categoryName)}</StyledCategory>
        </span>
      ),
      actions: (
        <ActionBox>
          <span>
            <DeleteButton id={item.id} />
          </span>
          <span>
            <GetAppIcon color="action" onClick={(e) => downloadVideo(e, item.url)} />
          </span>
        </ActionBox>
      ),
    };
  });
  return tableContent;
};
