import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { VenueAttributesDTO, VenueDTO } from '../storeModels';
import { httpClient } from '../../services/httpClient/httpClient';
import { VenueEndpoints, getApiUrlForId } from '../../api/endpoints';
import {
  CreateOrUpdateVenueAttributesRequest,
  CreateOrUpdateVenueAttributesResponse,
  VenueAPIModel,
  VenueAttributesAPIModel,
  PatchVenue,
  PatchVenueOptions,
} from '../../api/models/venue';

interface VenueSlice {
  venueAttributes: VenueAttributesDTO;
  venue: VenueDTO;
}

const initialState: VenueSlice = {
  venueAttributes: {
    attributes: {},
    error: false,
    isLoading: false,
    lastUpdated: new Date().toISOString(),
  },
  venue: {
    organizationName: '',
    city: '',
    id: '',
    logo: '',
    orgId: '',
    name: '',
    subdomain: '',
    timeZone: '',
    error: false,
    isLoading: false,
    lastUpdated: new Date().toISOString(),
  },
};
interface GetVenuOptions {
  id: string;
}
export const getVenue = createAsyncThunk(
  'venue/getVenue',
  async (options: GetVenuOptions, { rejectWithValue }) => {
    try {
      return await httpClient.get<undefined, VenueAPIModel>({
        url: getApiUrlForId(VenueEndpoints.GetVenueByIdOrSubdomain, options.id),
        requiresToken: true,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const patchVenue = createAsyncThunk(
  'venue/patchVenue',
  async (options: PatchVenueOptions, { rejectWithValue }) => {
    return await httpClient.put<PatchVenue, VenueAPIModel>({
      url: getApiUrlForId(VenueEndpoints.PatchVenue, options.id),
      payload: options.update,
      requiresToken: true,
    });
  },
);

export const getAttributes = createAsyncThunk(
  'venue/getAttributes',
  async (_options: undefined, { rejectWithValue }) => {
    try {
      return await httpClient.get<undefined, VenueAttributesAPIModel>({
        url: VenueEndpoints.GetVenueAttributes,
        requiresToken: true,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const createOrUpdateVenueAttribute = createAsyncThunk(
  'venue/createOrUpdateVenueAttribute',
  async (_options: CreateOrUpdateVenueAttributesRequest) => {
    return await httpClient.post<
      CreateOrUpdateVenueAttributesRequest,
      CreateOrUpdateVenueAttributesResponse
    >({
      url: VenueEndpoints.CreateOrUpdateVenueAttributes,
      payload: _options,
      requiresToken: true,
    });
  },
);

// TODO: simplify loading and error cases
const venueSlice = createSlice({
  name: 'venue',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(patchVenue.pending, (state) => {
      state.venue.isLoading = true;
    });
    reducersBuilder.addCase(patchVenue.fulfilled, (state, { payload }) => {
      state.venue.logo = payload.logo;
      state.venue.isLoading = false;
    });
    reducersBuilder.addCase(createOrUpdateVenueAttribute.pending, (state) => {
      state.venueAttributes.isLoading = true;
    });
    reducersBuilder.addCase(createOrUpdateVenueAttribute.fulfilled, (state, { payload }) => {
      state.venueAttributes.attributes[payload[0].name] = payload[0].value;
      state.venueAttributes.isLoading = false;
    });
    reducersBuilder.addCase(getVenue.rejected, (state) => {
      state.venue.error = true;
      state.venue.isLoading = false;
    });
    reducersBuilder.addCase(getVenue.pending, (state) => {
      state.venue.isLoading = true;
      state.venue.error = false;
    });
    reducersBuilder.addCase(getVenue.fulfilled, (state, { payload }) => {
      state.venue.isLoading = false;
      state.venue.error = false;
      state.venue.id = payload.id;
      state.venue.name = payload.name;
      state.venue.organizationName = payload.organizationName;
      state.venue.city = payload.city;
      state.venue.logo = payload.logo;
      state.venue.timeZone = payload.timeZone;
      state.venue.subdomain = payload.subdomain;
      state.venue.orgId = payload.orgId;
    });
    reducersBuilder.addCase(getAttributes.rejected, (state) => {
      state.venueAttributes.error = true;
      state.venueAttributes.isLoading = false;
    });
    reducersBuilder.addCase(getAttributes.pending, (state) => {
      state.venueAttributes.isLoading = true;
      state.venueAttributes.error = false;
    });
    reducersBuilder.addCase(getAttributes.fulfilled, (state, { payload }) => {
      state.venueAttributes.isLoading = false;
      state.venueAttributes.error = false;
      state.venueAttributes.lastUpdated = new Date().toISOString();
      state.venueAttributes.attributes = payload;
    });
  },
});

export const { reset } = venueSlice.actions;
export default venueSlice.reducer;
