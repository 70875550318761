import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { patchMe } from '../../../store/slices/meSlice';
import { useToasts } from 'react-toast-notifications';

import { SettingsPageStrings } from '../../../common/localization/en';

import { ProfileInitialValues, validationSchema } from './ProfileSection.helper';

import { TextFieldComponent } from '../../../common/components/TextField/TextField';

import { Container, FormStyled, SubmitButton, Title } from './ProfileSection.style';
import { PatchmeOptions } from '../../../api/models/auth';

export const ProfileSection: React.FC = () => {
  const { addToast } = useToasts();
  const dispatch = useAppDispatch();
  const { name, email, isLoading, id, photoUrl } = useTypedSelector((state) => state.me);

  const onSubmitHandler = (values: PatchmeOptions) => {
    dispatch(patchMe(values));
    addToast('Profile is updated', {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  const formikProfile = useFormik({
    initialValues: {
      name,
      email,
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object().shape<ProfileInitialValues>(validationSchema),
    onSubmit: (values) => {
      onSubmitHandler({ name: values.name, photoUrl, id });
    },
  });
  const { handleSubmit, values, handleChange, errors } = formikProfile;

  return (
    <Container>
      <Title>{SettingsPageStrings.ProfileSettings}</Title>
      <FormStyled onSubmit={handleSubmit}>
        <TextFieldComponent
          name="name"
          label={SettingsPageStrings.FullName}
          value={values.name}
          onChange={handleChange}
          errorText={errors.name}
        />
        <TextFieldComponent
          name="email"
          label={SettingsPageStrings.Email}
          value={values.email}
          onChange={handleChange}
          errorText={errors.email}
        />
        <SubmitButton disabled={isLoading} variant="contained" color="primary" type="submit">
          {SettingsPageStrings.ProfileSubmitButton}
        </SubmitButton>
      </FormStyled>
    </Container>
  );
};
