import React from 'react';

export const ArrowLeftIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
    <path
      fill="#fff"
      d="M15 7H3.83l4.88-4.88c.39-.39.39-1.03 0-1.42A.996.996 0 007.3.7L.71 7.29a.996.996 0 000 1.41l6.59 6.59a.996.996 0 101.41-1.41L3.83 9H15c.55 0 1-.45 1-1s-.45-1-1-1z"
    />
  </svg>
);
