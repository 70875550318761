import React from 'react';

import { ArrowLeftIcon } from '../../../../assets/newDesign/ArrowLeftIcon';
import { ArrowRightIcon } from '../../../../assets/newDesign/ArrowRightIcon';

import { Container } from './CollapseSection.style';

interface CollapseSectionProps {
  onCollapse: () => void;
  collapsed: boolean;
}

export const CollapseSection: React.FC<CollapseSectionProps> = ({ onCollapse, collapsed }) => {
  const ArrowBlock = () => {
    if (collapsed) {
      return <ArrowRightIcon />;
    }

    return (
      <>
        Collapse Sidebar <ArrowLeftIcon />
      </>
    );
  };

  return <Container collapsed={collapsed} onClick={onCollapse}>{ArrowBlock()}</Container>;
};
