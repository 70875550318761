import React from 'react';

import { LoginContainer, LoginForm, LoginHeader, ForgotPasswordSubHeader } from '../Login.style';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { LoginPageStrings } from '../../../common/localization/en';
import { ILoginProps } from '../Login';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  ForgotPasswordFormValues,
  initialValuesForgotPasswordForm,
  validationSchemaForgotPassword,
} from '../LoginFormsHelpers';

export const ForgotPasswordRequestComponent: React.FC<ILoginProps> = ({ setStep }) => {
  const submitForm = (values: ForgotPasswordFormValues) => {
    setStep('forgotPasswordSuccess');
  };

  const ForgotPasswordFormik = useFormik({
    initialValues: initialValuesForgotPasswordForm,

    enableReinitialize: true,

    validateOnChange: false,

    validateOnBlur: false,

    validationSchema: Yup.object().shape<ForgotPasswordFormValues>(validationSchemaForgotPassword),

    onSubmit: (values) => {
      submitForm(values);
    },
  });

  const { handleSubmit, values, handleChange, errors } = ForgotPasswordFormik;

  return (
    <>
      <LoginContainer>
        <LoginForm onSubmit={handleSubmit}>
          <Grid container spacing={3} direction="row-reverse" justify="space-between">
            <Grid item xs={12}>
              <LoginHeader>{LoginPageStrings.ForgotPasswordTitle}</LoginHeader>
            </Grid>

            <Grid item xs={12}>
              <ForgotPasswordSubHeader>
                {LoginPageStrings.ForgotPasswordSubtitle}
              </ForgotPasswordSubHeader>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                error={errors.email ? true : false}
                helperText={errors.email}
                name="email"
                value={values.email}
                id="outlined-error-helper-text"
                label={LoginPageStrings.Email}
                variant="outlined"
                placeholder={LoginPageStrings.UserNameLabel}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Send
              </Button>
            </Grid>

            <Grid item xs={12} md={2}>
              <Button color="primary" onClick={() => setStep('login')} fullWidth>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </LoginForm>
      </LoginContainer>
    </>
  );
};
