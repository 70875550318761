import styled from 'styled-components';

import Button from '@material-ui/core/Button';

export const Container = styled.div``;

export const Title = styled.h2`
  margin-bottom: 27px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
`;

export const SubmitButton = styled(Button)`
  align-self: flex-end;
`;

export const FormStyled = styled.form`
  width: '100%';
  display: flex;
  flex-direction: column;

  > div {
    margin-bottom: 24px;
  }
`;
