import React from 'react';
import { WebsiteRoutes } from '../../../../constants/routes';
import { SidebarStrings } from '../../../../localization/en';

import { FeedIcon } from '../../../../assets/newDesign/FeedIcon';
import { SettingsIcon } from '../../../../assets/newDesign/SettingsIcon';
import { InfluencersIcon } from '../../../../assets/newDesign/InfluencersIcon';

export const checkIsActiveItem = (pathname: string, route: string): boolean => {
  return pathname.indexOf(route) >= 0;
};

export interface ISubMenuItem {
  icon: React.ReactElement;
  text: string;
  notification?: number;
  route: string;
}

export interface IMenuItem {
  icon: React.ReactElement;
  text: string;
  notification?: number;
  route: string;
  isSubMenu: boolean;
  subMenuItems?: ISubMenuItem[];
}

export const menuItems: IMenuItem[] = [
  {
    icon: <FeedIcon />,
    text: SidebarStrings.Content,
    route: WebsiteRoutes.Content,
    isSubMenu: false,
  },
  {
    icon: <InfluencersIcon />,
    text: SidebarStrings.Heroes,
    route: WebsiteRoutes.Heroes,
    isSubMenu: false,
  },
  {
    icon: <SettingsIcon />,
    text: SidebarStrings.Settings,
    route: WebsiteRoutes.Settings,
    isSubMenu: false,
  },
];

export const checkIsActiveSubMenuItem = (pathname: string, item: IMenuItem) => {
  if (!item.subMenuItems) {
    return false;
  }

  const activeItem = item.subMenuItems.find(
    (subMenuItem: ISubMenuItem) => subMenuItem.route === pathname
  );

  return Boolean(activeItem);
};
