import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import ToolBar from '@material-ui/core/Toolbar';
import { SVBreadCrumbs } from '../../breadCrumbs/BreadCrumbs';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      boxShadow: 'unset',
    },
    toolBar: {
      backgroundColor: theme.palette.common.white,
      height: '80px',
    },
    toolBarTablet: {
      backgroundColor: theme.palette.common.white,
      boxShadow: '0px 4px 18px -2px rgba(10, 25, 107, 0.1)',
      height: '72px',
    },
  })
);

export const TopBarDesktop: React.FC = () => {
  const classes = useStyles();
  return (
    <AppBar position="static" className={classes.appBar}>
      <ToolBar className={classes.toolBar}>
        <SVBreadCrumbs />
      </ToolBar>
    </AppBar>
  );
};
