import * as Yup from 'yup';

export interface ResetPasswordInitialValues {
  oldPassword: string;
  newPassword: string;
}

export const initialValues: ResetPasswordInitialValues = {
  oldPassword: '',
  newPassword: '',
};

export const validationSchema: Yup.ObjectSchemaDefinition<ResetPasswordInitialValues> = {
  oldPassword: Yup.string()
    .min(6, 'Password must be at least 6 characters long')
    .required('Required'),
  newPassword: Yup.string()
    .min(6, 'Password must be at least 6 characters long')
    .required('Required'),
};
