import { createSlice } from '@reduxjs/toolkit';
import { UIState } from '../storeModels';

const initialState: UIState = {
  sidebarOpen: false,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    triggerSidebar(state) {
      state.sidebarOpen = !state.sidebarOpen;
    },
    reset: () => initialState,
  },
});

export const { triggerSidebar, reset } = uiSlice.actions;
export default uiSlice.reducer;
