import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { LoginActionTypes, useLoginReducer } from './LoginState';
import { LoginPageStrings } from '../../common/localization/en';
import { LoginContainer, LoginForm, LoginHeader, LoginSubHeader } from './Login.style';
import { useAppDispatch, useTypedSelector } from '../../store';
import { authRequest, ResetAuthError } from '../../store/slices/meSlice';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { ILoginProps } from './Login';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  LoginFormValues,
  initialValuesLoginForm,
  validationSchemaLoginForm,
} from './LoginFormsHelpers';

export const LoginFormComponent: React.FC<ILoginProps> = ({ setStep }) => {
  const history = useHistory();

  const appDispatch = useAppDispatch();

  const [loginData, dispatch] = useLoginReducer();

  const [showPassword, setShowPassword] = useState(false);

  const { auth, authError } = useTypedSelector((state) => ({
    auth: state.me.auth,

    authError: state.me.authError,
  }));
  useEffect(() => {
    if (auth) {
      history.push('/dashboard');
    }
  }, [auth, history]);

  const LoginFormik = useFormik({
    initialValues: initialValuesLoginForm,

    enableReinitialize: true,

    validateOnChange: false,

    validateOnBlur: false,

    validationSchema: Yup.object().shape<LoginFormValues>(validationSchemaLoginForm),

    onSubmit: (values) => {
      submitForm(values);
    },
  });

  const { handleSubmit, values, handleChange, errors } = LoginFormik;

  useEffect(() => {
    dispatch({
      type: LoginActionTypes.SET_ACTIVE_SUBMIT_BUTTON,

      payload: true,
    });
  }, [values.email, values.password, dispatch, appDispatch, authError]);

  const submitForm = (values: LoginFormValues) => {
    dispatch({
      type: LoginActionTypes.SET_ACTIVE_SUBMIT_BUTTON,

      payload: false,
    });

    appDispatch(authRequest({ username: values.email, password: values.password }));
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const resetAuthError = () => {
    if (authError) {
      appDispatch(ResetAuthError());
    }
  };
  return (
    <LoginContainer>
      <LoginForm onSubmit={handleSubmit}>
        <Grid container spacing={3} direction="row" justify="center" alignItems="center">
          <Grid item xs={12} md={8}>
            <LoginHeader>{LoginPageStrings.Title}</LoginHeader>
          </Grid>

          <Grid item xs={12} md={8}>
            <LoginSubHeader>{LoginPageStrings.Subtitle}</LoginSubHeader>
          </Grid>

          <Grid item xs={12} md={8}>
            <TextField
              fullWidth
              error={(errors.email && true) || authError}
              name="email"
              value={values.email}
              id="email"
              label={LoginPageStrings.Email}
              helperText={
                (errors.email && LoginPageStrings.EmailValidationError) ||
                (authError && LoginPageStrings.InvalidUsername)
              }
              variant="outlined"
              placeholder={LoginPageStrings.UserNameLabel}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} md={8}>
            <FormControl
              variant="outlined"
              fullWidth
              error={(errors.password && true) || authError}
            >
              <InputLabel htmlFor="password">{LoginPageStrings.Password}</InputLabel>

              <OutlinedInput
                id="password"
                type={showPassword ? 'text' : 'password'}
                value={values.password}
                placeholder={LoginPageStrings.Password}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />

              {(errors.password || authError) && (
                <FormHelperText id="standard-weight-helper-text">
                  {LoginPageStrings.InvalidPassword}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          {/* <Grid item xs={12}>

            {loginData.authError && <ErrorText>{LoginPageStrings.AuthError}</ErrorText>}

          </Grid> */}
          <Grid item xs={12} md={8}>
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Grid item xs={8} md={5}>
                <Button color="secondary" onClick={() => setStep('forgotPasswordRequest')}>
                  Forgot Password ?
                </Button>
              </Grid>
              <Grid item xs={4} md={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color={loginData.activeSubmitButton ? 'primary' : 'secondary'}
                  disabled={!loginData.activeSubmitButton}
                  onClick={resetAuthError}
                >
                  Login
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </LoginForm>
    </LoginContainer>
  );
};
