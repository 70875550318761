import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Collapse, List, ListItem, ListItemText } from '@material-ui/core';

import { ExpandLessIcon } from '../../../../../assets/newDesign/ExpandLessIcon';
import { ExpandMoreIcon } from '../../../../../assets/newDesign/ExpandMoreIcon';

import { checkIsActiveItem, IMenuItem } from '../Menu.helper';

import { SVBadge } from '../Menu.style';
import {
  Container,
  SubItemIcon,
  SVListItem,
  SVListItemIcon,
  SubMenuArrowsWrapper,
} from './SubMenu.style';

interface SubMenuProps {
  item: IMenuItem;
  isSubMenuCollapsed: boolean;
  collapsed?: boolean;
  onClickCollapseManagerHandler: () => void;
}

export const SubMenu: React.FC<SubMenuProps> = ({
  item,
  isSubMenuCollapsed,
  collapsed,
  onClickCollapseManagerHandler,
}) => {
  const { pathname } = useLocation();
  const history = useHistory();

  const redirectToPath = (path: string) => () => {
    if (collapsed) {
      onClickCollapseManagerHandler();
    }
    history.push(path);
  };

  const renderSubMenuArrows = () => {
    return isSubMenuCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />;
  };

  const renderSubMenu = () => {
    if (!item.subMenuItems) {
      return null;
    }

    return item.subMenuItems.map((subMenuItem) => {
      const subItemIconColor = checkIsActiveItem(pathname, subMenuItem.route)
        ? 'var(--sidebarActiveSubIconColor)'
        : 'var(--sidebarSubIconColor)';

      return (
        <Collapse key={subMenuItem.text} in={isSubMenuCollapsed} timeout="auto" unmountOnExit>
          <List component="nav" disablePadding>
            <ListItem button onClick={redirectToPath(subMenuItem.route)}>
              <SubItemIcon>
                {React.cloneElement(subMenuItem.icon as React.ReactElement, {
                  color: subItemIconColor,
                })}
              </SubItemIcon>
              <ListItemText primary={subMenuItem.text} />
              <SVBadge badgeContent={subMenuItem.notification} />
            </ListItem>
          </List>
        </Collapse>
      );
    });
  };

  const onClickListArrowItem = () => {
    return !collapsed ? onClickCollapseManagerHandler : () => {};
  };

  const renderListItemText = () => {
    const onClick = collapsed ? redirectToPath(item.route) : () => {};

    return <ListItemText primary={item.text} onClick={onClick} />;
  };

  return (
    <Container>
      <SVListItem button>
        {!collapsed && (
          <SVListItemIcon>
            {React.cloneElement(item.icon as React.ReactElement, {
              color: 'var(--sidebarActiveIconColor)',
            })}
          </SVListItemIcon>
        )}
        {renderListItemText()}
        <SubMenuArrowsWrapper onClick={onClickListArrowItem()}>
          {renderSubMenuArrows()}
        </SubMenuArrowsWrapper>
      </SVListItem>
      {renderSubMenu()}
    </Container>
  );
};
