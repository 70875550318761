import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import ToolBar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import { triggerSidebar } from '../../../../store/slices/uiSlice';
import { useAppDispatch } from '../../../../store';
import { SVBreadCrumbs } from '../../breadCrumbs/BreadCrumbs';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      boxShadow: '0px 4px 18px -2px rgba(10, 25, 107, 0.1)',
    },
    toolBarTablet: {
      backgroundColor: theme.palette.common.white,
      boxShadow: '0px 4px 18px -2px rgba(10, 25, 107, 0.1)',
      height: '72px',
    },
    menuIcon: {
      color: theme.palette.common.black,
      marginRight: theme.spacing(2),
    },
  })
);

export const TopBarTablet: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const openSideBar = () => {
    dispatch(triggerSidebar());
  };
  return (
    <AppBar position="static" className={classes.appBar}>
      <ToolBar className={classes.toolBarTablet}>
        <MenuIcon className={classes.menuIcon} onClick={openSideBar} />
        <SVBreadCrumbs />
      </ToolBar>
    </AppBar>
  );
};
