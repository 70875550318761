import React from 'react';
import { useHistory } from 'react-router';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { websiteRouteNameConnections } from '../../constants/routes';
import { BreadCrumbItem } from './BreadCrumbs.style';

// Obviously we'll need props here, let's add them later
export const SVBreadCrumbs: React.FC = () => {
  const history = useHistory();
  const currentRoute = websiteRouteNameConnections.find(
    (websiteRouteNameConnection) => websiteRouteNameConnection.route === history.location.pathname
  );

  const onLinkClick = (link: string) => {
    history.push(link);
  };

  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
      <BreadCrumbItem onClick={() => onLinkClick(currentRoute?.route || '/')}>
        {currentRoute?.name}
      </BreadCrumbItem>
    </Breadcrumbs>
  );
};
