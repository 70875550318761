import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { httpClient } from '../../services/httpClient/httpClient';
import { StoryVideosEndpoints, getApiUrlForId } from '../../api/endpoints';
import { GetTableDataResponse } from '../../api/models/common';
import {
  StoryApiModel,
  GetStoriesRequest,
  DeleteStoryRequest,
  DeleteStoryResponse,
  GetStoriesRequestOptions,
} from '../../api/models/storyVideos';
import { setTableSorting, tableLoadFulFilled, tableLoadPending, tableLoadRejected } from './utils';
import { defaultPagination } from '../../common/constants/constants';
import { TableDTO } from '../storeModels';

const initialState: TableDTO<StoryApiModel> = {
  error: false,
  isLoading: false,
  items: [],
  page: defaultPagination.page,
  size: defaultPagination.size,
  totalItems: defaultPagination.totalItems,
  totalPages: defaultPagination.totalPages,
  sort: defaultPagination.sortByLastCreated,
  lastUpdated: new Date().toISOString(),
};

export const getStoryVideos = createAsyncThunk(
  'videos/getStoryVideos',
  async (options: GetStoriesRequestOptions, { rejectWithValue }) => {
    try {
      return await httpClient.get<GetStoriesRequest, GetTableDataResponse<StoryApiModel>>({
        url: StoryVideosEndpoints.GetVideos,
        requiresToken: true,
        params: {
          venueId: options.venueId,
          userId: options.userId,
          page: options.pageable.page?.toString(),
          size: options.pageable.size?.toString(),
          sort: options.pageable.sort?.toString(),
          search: options.search,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);
export const deleteStoryById = createAsyncThunk(
  'videos/deleteStory',
  async (options: DeleteStoryRequest, { rejectWithValue }) => {
    try {
      return await httpClient.delete<DeleteStoryRequest, DeleteStoryResponse>({
        url: getApiUrlForId(StoryVideosEndpoints.DeleteStoryById, options.id),
        requiresToken: true,
        params: options,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const storyVideosSlice = createSlice({
  name: 'videos',
  initialState,
  reducers: {
    goToSelectedPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setVideosSorting(state, action: PayloadAction<string>) {
      return setTableSorting(state, action.payload);
    },
    updateTable(state) {
      state.lastUpdated = new Date().toISOString();
    },
    reset: () => initialState,
    updateSize(state, action: PayloadAction<number>) {
      state.size = action.payload;
      state.page = 0;
    },
    updateSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
      state.page = initialState.page;
    },
  },
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(getStoryVideos.rejected, (state) => {
      return tableLoadRejected(state);
    });
    reducersBuilder.addCase(getStoryVideos.pending, (state) => {
      return tableLoadPending(state);
    });
    reducersBuilder.addCase(getStoryVideos.fulfilled, (state, { payload }) => {
      let items: StoryApiModel[];
      if (payload.page === 0) {
        items = payload.items;
      } else {
        items = [...state.items, ...payload.items];
      }

      payload.items = items;

      return tableLoadFulFilled(state, payload);
    });
    reducersBuilder.addCase(deleteStoryById.fulfilled, (state, { payload }) => {
      state.items = state.items.filter((item) => item.id !== payload.id);
    });
  },
});

export const {
  goToSelectedPage,
  updateTable,
  setVideosSorting,
  reset,
  updateSize,
  updateSearch,
} = storyVideosSlice.actions;
export default storyVideosSlice.reducer;
