import React from 'react';

import { menuItems } from './Menu.helper';

import { MenuItem } from './menuItem/MenuItem';
import { MenuItemCollapsed } from './menuItemCollapsed/MenuItemCollapsed';

import { Container, SVList } from './Menu.style';

interface MenuProps {
  collapsed: boolean;
  itemClickHandler?: () => void;
}

export const Menu: React.FC<MenuProps> = ({ collapsed, itemClickHandler }) => {
  const renderItems = () =>
    menuItems.map((item) => {
      return collapsed ? (
        <MenuItemCollapsed item={item} key={item.route} />
      ) : (
        <MenuItem item={item} key={item.route} itemClickHandler={itemClickHandler || (() => {})} />
      );
    });

  return (
    <Container>
      <SVList>{renderItems()}</SVList>
    </Container>
  );
};
