import React, { useEffect } from 'react';
import { Box, CardMedia, Grid } from '@material-ui/core';

import { UserApiModel } from '../../../../api/models/users';
import { theme } from '../../../../theme';

import noAvatar from '../../../assets/no-avatar.jpg';
import { createDisplayData } from './helpers/heroDetailsUtils';
import { VideosSection } from '../VideosSection/VideosSection';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { getStoryVideos } from '../../../../store/slices/storyVideosSlice';
import { useWindowResize } from '../../../../services/hooks/useWindowResize';
import { LayoutMode } from '../../../constants/constants';

export enum SocialTypes {
  instagram = 'INSTAGRAM',
  tiktok = 'TIKTOK',
  facebook = 'FACEBOOK',
}

interface ModalInfoProps {
  userData: UserApiModel;
}
export const ModalInfo: React.FC<ModalInfoProps> = ({ userData }) => {
  const dispatch = useAppDispatch();
  const device = useWindowResize();
  const { photoUrl, id, venueId } = userData;
  const { items, isLoading, error, size, page, sort, search } = useTypedSelector(
    (state) => state.storyVideos,
  );
  useEffect(() => {
    dispatch(
      getStoryVideos({
        venueId: venueId,
        userId: id,
        pageable: { page: page, size: size, sort: sort },
        search: search,
      }),
    );
  }, [dispatch, page, size, sort, id, venueId, search]);
  const displayData = createDisplayData(userData);
  const imageDimensionSize = device === LayoutMode.Desktop ? '100px' : '75px';
  const fontSize = device === LayoutMode.Desktop ? '14px' : '10px';

  return (
    <Box boxSizing="border-box" paddingX={4} paddingY={3}>
      <Grid container spacing={device === LayoutMode.Desktop ? 2 : undefined}>
        <Grid item xs={5} sm={3}>
          <Box
            width={imageDimensionSize}
            height={imageDimensionSize}
            borderRadius="8px"
            overflow="hidden"
          >
            <CardMedia component="img" src={photoUrl || noAvatar} />
          </Box>
        </Grid>
        <Grid item xs={7} sm={9}>
          {Object.values(displayData).map((row, index) => (
            <Grid
              key={`${row.text}${index}}`}
              container
              style={{ color: `${theme.palette.text.primary}`, fontSize: fontSize }}
              spacing={2}
            >
              <Grid item xs={5} sm={3}>
                {row.text}
              </Grid>
              <Grid item xs={7} sm={9}>
                {row.value}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Box mb={2} />
      {!error && !isLoading && <VideosSection items={items} />}
    </Box>
  );
};
