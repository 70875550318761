import React, { useEffect } from 'react';
import {
  convertApiPageToFrontEndPage,
  convertFrontEndPageToApiPage,
} from '../../../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { createUserDataTableContent } from '../../../../services/helpers/tableMappers';
import {
  getHeroes,
  goToSelectedPage,
  setUserDataSorting,
  updateSize,
} from '../../../../store/slices/heroesSlice';
import { CommonTable } from '../../../../common/components/table/CommonTable';
import { useHistory } from 'react-router';

export const HeroesTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const {
    isLoading,
    error,
    items,
    totalPages,
    totalItems,
    page,
    size,
    sort,
    search = '',
  } = useTypedSelector((state) => state.influencers);
  const venueId = useTypedSelector((state) => state.me.venueId);

  useEffect(() => {
    dispatch(getHeroes({ search, venueId, pageable: { page, size, sort } }));
  }, [dispatch, page, size, sort, search, venueId]);

  const userDataTableContents = createUserDataTableContent({
    items,
  });

  const onGoToPage = (targetPage: number) => {
    dispatch(goToSelectedPage(convertFrontEndPageToApiPage(targetPage)));
  };

  const onSort = (name: string) => {
    dispatch(setUserDataSorting(name));
  };

  const onClickRow = (id: string) => {
    history.push(`/heroes/${id}`);
  };

  const changeSize = (size: number) => {
    dispatch(updateSize(size));
  };
  return (
    <>
      <CommonTable
        content={userDataTableContents}
        page={convertApiPageToFrontEndPage(page)}
        sort={sort}
        totalItems={totalItems}
        totalPages={totalPages}
        isLoading={isLoading}
        noContent={error}
        tablePadding="0"
        goToPage={onGoToPage}
        onSortHeaderClick={onSort}
        onClickRow={onClickRow}
        size={size}
        onSizeChange={changeSize}
      />
    </>
  );
};
