import React from 'react';

interface FeedIconProps {
  color?: string;
}

export const FeedIcon: React.FC<FeedIconProps> = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill={color}
        fillRule="evenodd"
        d="M18 4h-8c-1.1 0-2 .9-2 2v11c0 .5-.5 1-1 1-.6 0-1-.4-1-1V9c0-.6-.4-1-1-1s-1 .4-1 1v8c0 1.2.7 2.2 1.7 2.7.4.2.8.3 1.3.3h11c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14h-8V6h8v12zm-5-8h2c.6 0 1-.4 1-1s-.4-1-1-1h-2c-.6 0-1 .4-1 1s.4 1 1 1zm0 3h2c.6 0 1-.4 1-1s-.4-1-1-1h-2c-.6 0-1 .4-1 1s.4 1 1 1z"
        clipRule="evenodd"
      />
    </svg>
  );
};
