import React from 'react';
import { ContentContainer } from './TableSection.style';
import { SearchBar } from '../../../common/components/searchBar/SearchBar';
import { ContentTable } from './contentTable/ContentTable';
import { updateSearch } from '../../../store/slices/storyVideosSlice';

export const TableSection: React.FC = () => {
  return (
    <ContentContainer>
      <SearchBar entity="storyVideos" updateSearch={updateSearch} />
      <ContentTable />
    </ContentContainer>
  );
};
