import styled from 'styled-components';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';

export const Container = styled.div`
  position: relative;
`;

export const SubMenuWrapper = styled.div`
  position: absolute;
  width: 216px;
  left: 100%;
  top: 0;
  z-index: 1;

  > div > div:first-child {
    padding-left: 47px;
  }
`;

export const SVListItem = styled(ListItem)``;

export const SVListItemIcon = styled(ListItemIcon)`
  margin: auto;
`;
