import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: fit-content;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h3`
  margin-bottom: 8px;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: #0b79d0;
`;

export const UploadButton = styled.div`
  margin-top: 16px;
  > span {
    padding: 4px 11px;
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    display: flex;
    align-items: center;
    letter-spacing: 0.46px;
    text-transform: capitalize;
    color: var(--primaryColor);
    background: #dbefff;
    border-radius: 4px;
    flex-shrink: 0;
  }
`;

export const PictureWrapper = styled.div`
  width: 242px;
  height: 198px;
  background: var(--white);
  border-radius: 20px;
  object-position: center;
  object-fit: cover;
  flex-shrink: 0;
  overflow: hidden;
`;

export const PicturedLabel = styled.label``;

export const PicturedInput = styled.input`
  display: none;
`;
