import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  MeApiModel,
  PatchMe,
  UpdatePassword,
  PatchmeOptions,
  ResetPasswordOptions,
} from '../../api/models/auth';
import { httpClient } from '../../services/httpClient/httpClient';
import { AuthEndpoints, getApiUrlForId } from '../../api/endpoints';
import { MeDTO } from '../storeModels';
import { GetMeRequestOptions } from '../../api/models/auth';

const authDataFromLocalStorage = localStorage.getItem('authResponse')
  ? JSON.parse(localStorage.getItem('authResponse') || '')
  : null;

const mapAuthResponseToMeDTO = (authresponse: MeApiModel): MeDTO => {
  return {
    accessScopes: authresponse.customer.accessScopes,
    email: authresponse.customer.email,
    createdAt: authresponse.customer.createdAt,
    photoUrl: authresponse.customer.photoUrl,
    updatedAt: authresponse.customer.updatedAt,
    name: authresponse.customer.name,
    enabled: authresponse.customer.enabled,
    isLoading: authresponse.customer.isLoading,
    id: authresponse.customer.id,
    venueId: authresponse.customer.venueId,
    auth: false,
    authError: false,
  };
};

const initialState: MeDTO = (authDataFromLocalStorage &&
  mapAuthResponseToMeDTO(authDataFromLocalStorage)) || {
  accessScopes: [],
  email: '',
  createdAt: '',
  photoUrl: '',
  updatedAt: '',
  name: '',
  enabled: false,
  isLoading: false,
  id: '',
  venueId: '',
  auth: false,
  authError: false,
};

export const authRequest = createAsyncThunk(
  'me/auth',
  async (values: { username: string; password: string }, { rejectWithValue }) => {
    try {
      return await httpClient.login({
        url: AuthEndpoints.NewLogin,
        payload: { email: values.username, password: values.password },

        requiresToken: false,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getMe = createAsyncThunk(
  'me/getMe',
  async (_options: GetMeRequestOptions, { rejectWithValue }) => {
    try {
      return await httpClient.get<GetMeRequestOptions, MeApiModel>({
        url: getApiUrlForId(AuthEndpoints.GetMe, _options.id),
        requiresToken: true,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const patchMe = createAsyncThunk(
  'venue/patchMe',
  async (_options: PatchmeOptions, { rejectWithValue }) => {
    return await httpClient.put<PatchMe, MeDTO>({
      url: getApiUrlForId(AuthEndpoints.PatchMe, _options.id),
      payload: { name: _options.name, photoUrl: _options.photoUrl },
      requiresToken: true,
    });
  },
);

export const resetPasswordMe = createAsyncThunk(
  'venue/resetPasswordMe',
  async (_options: ResetPasswordOptions, { rejectWithValue }) => {
    return await httpClient.put<UpdatePassword, MeDTO>({
      url: getApiUrlForId(AuthEndpoints.PatchMe, _options.id),
      payload: { currentPassword: _options.currentPassword, newPassword: _options.newPassword },
      requiresToken: true,
    });
  },
);

const meSlice = createSlice({
  name: 'me',
  initialState,
  reducers: {
    updateMe(state, action: PayloadAction<MeApiModel>) {
      state.accessScopes = action.payload.customer.accessScopes;
      state.email = action.payload.customer.email;
      state.name = action.payload.customer.name;
      state.enabled = action.payload.customer.enabled;
      state.id = action.payload.customer.id;
      state.venueId = action.payload.customer.venueId;
      state.photoUrl = action.payload.customer.photoUrl;
      state.updatedAt = action.payload.customer.updatedAt;
    },
    reset: () => initialState,
    ResetAuthError(state) {
      state.authError = false;
    },
  },
  extraReducers: (reducerBuilder) => {
    reducerBuilder.addCase(patchMe.pending, (state) => {
      state.isLoading = true;
    });
    reducerBuilder.addCase(patchMe.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.email = payload.email;
      state.name = payload.name;
    });
    reducerBuilder.addCase(resetPasswordMe.pending, (state) => {
      state.isLoading = true;
    });
    reducerBuilder.addCase(resetPasswordMe.fulfilled, (state, { payload }) => {
      state.isLoading = false;
    });
    reducerBuilder.addCase(getMe.pending, (state) => {
      state.isLoading = true;
    });
    reducerBuilder.addCase(getMe.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.accessScopes = payload.customer.accessScopes;
      state.email = payload.customer.email;
      state.name = payload.customer.name;
      state.enabled = payload.customer.enabled;
      state.id = payload.customer.id;
      state.venueId = payload.customer.venueId;
      state.photoUrl = payload.customer.photoUrl;
      state.updatedAt = payload.customer.updatedAt;
    });
    reducerBuilder.addCase(authRequest.fulfilled, (state, { payload }) => {
      state.auth = true;
      state.authError = false;
      state.accessScopes = payload.customer.accessScopes;
      state.email = payload.customer.email;
      state.name = payload.customer.name;
      state.enabled = payload.customer.enabled;
      state.id = payload.customer.id;
      state.venueId = payload.customer.venueId;
      state.photoUrl = payload.customer.photoUrl;
      state.updatedAt = payload.customer.updatedAt;
    });
    reducerBuilder.addCase(authRequest.rejected, (state, { payload }) => {
      state.authError = true;
    });
  },
});

export const { updateMe, reset, ResetAuthError } = meSlice.actions;
export default meSlice.reducer;
